@use 'sass:color';
@use '@angular/material' as mat;
@use 'sass:meta';
@use '../variables';
@use '../color-themes';
@use '../placeholders';
@include mat.elevation-classes();
@include mat.app-background();

// Foreground Elements
// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
    base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text
);

// Dark Theme text
$light-text: #fff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: color.adjust($light-background, $lightness: -5%);
$light-bg-darker-10: color.adjust($light-background, $lightness: -10%);
$light-bg-darker-20: color.adjust($light-background, $lightness: -20%);
$light-bg-darker-30: color.adjust($light-background, $lightness: -30%);
$light-bg-lighter-5: color.adjust($light-background, $lightness: 5%);
$dark-bg-tooltip: color.adjust(#151521, $lightness: 20%);
$dark-bg-alpha-4: rgba(#151521, 0.04);
$dark-bg-alpha-12: rgba(#151521, 0.12);

$mat-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10
);

// Dark bg
$dark-background: #151521;
$dark-bg-lighter-5: color.adjust($dark-background, $lightness: 5%);
$dark-bg-lighter-10: color.adjust($dark-background, $lightness: 10%);
$dark-bg-lighter-20: color.adjust($dark-background, $lightness: 20%);
$dark-bg-lighter-30: color.adjust($dark-background, $lightness: 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10
);

// Theme Config
body {
    --primary-color: #7e94ac;
    --primary-lighter-color: #d8dfe6;
    --primary-darker-color: #617792;
    --text-primary-color: #{$dark-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
    main: #7e94ac,
    lighter: #d8dfe6,
    darker: #617792,
    200: #7e94ac,
    // For slide toggle,
    contrast: (
            main: $dark-primary-text,
            lighter: $dark-primary-text,
            darker: $light-primary-text
        )
);
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);

body {
    --accent-color: #bf9000;
    --accent-lighter-color: #ecdeb3;
    --accent-darker-color: #a97300;
    --text-accent-color: #{$dark-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
    main: #bf9000,
    lighter: #ecdeb3,
    darker: #a97300,
    200: #bf9000,
    // For slide toggle,
    contrast: (
            main: $dark-primary-text,
            lighter: $dark-primary-text,
            darker: $light-primary-text
        )
);
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);

body {
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
    main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000,
    // For slide toggle,
    contrast: (
            main: $light-primary-text,
            lighter: $dark-primary-text,
            darker: $light-primary-text
        )
);
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);

$default-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $theme-primary,
            accent: $theme-accent,
            warn: $theme-warn,
            is-dark: false,
            foreground: $mat-light-theme-foreground,
            background: $mat-light-theme-background
        )
    )
);
$dark-theme: mat.m2-define-dark-theme(
    (
        color: (
            primary: $theme-primary,
            accent: $theme-accent,
            warn: $theme-warn,
            is-dark: true,
            foreground: $mat-dark-theme-foreground,
            background: $mat-dark-theme-background
        )
    )
);

.theme--default {
    @include mat.elevation-classes();
    @include mat.app-background();
    @include mat.all-component-themes($default-theme);
}

.theme--dark {
    //@include mat.core-theme($dark-theme);
    @include mat.all-component-colors($dark-theme);
}

// Specific component overrides, pieces that are not in line with the general theming
// Handle buttons appropriately, with respect to line-height
.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
    padding: 0 1.15em;
    margin: 0 0.65em;
    min-width: 3em;
    line-height: 36.4px;
}

.mat-mdc-standard-chip {
    padding: 0.5em 0.85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: 'Material Icons', 'Material Icons';

    .mat-badge-content {
        font-family: 'Roboto';
    }
}

.mat-badge-content {
    background-color: #bb8d02 !important;
    color: #fff !important;
    font-weight: normal !important;
    text-overflow: inherit !important;
}

.pulse-dashboard {
    max-width: 98%;
    height: 100%;
    margin: auto;
    /* margin: 20px auto; */
}

.pulse-dashboard,
.pulse-section {
    .dx-field-label {
        padding: 8px 0px;
    }

    .nav-link {
        cursor: pointer;
    }

    .chart-MediumH,
    .dt-container {
        width: 100%;
    }

    .dataTable {
        thead tr th,
        tbody tr td {
            min-width: 50px;
        }

        thead th.t-c,
        thead th.expandable-cell,
        thead th.n-c,
        thead th.e-c,
        thead th.dt-orderable-none {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('t-c');
                //color: color-themes.get-theme-color('text');
            }
        }

        .l-c {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('l-c');
                color: color-themes.get-theme-color('text');
            }
        }

        .l-c-left {
            @include color-themes.themed('dx-pivot-table') {
                background-color: color-themes.get-theme-color(
                    'table-row-highlight'
                ); //rgba(126, 147, 172, 0.2);
            }

            text-align: left;
            padding-left: 4px;
        }

        .l-c-indent {
            background-color: #efefef;
            text-align: left;
            text-indent: 20px;
        }

        .upper-header-row th,
        .var-cell {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('highlight-row');
                color: color-themes.get-theme-color('text');
            }
        }

        .expandable-cell {
            min-height: 28px;
            display: table-cell;
        }

        .group-row {
            background-color: #7e94ac;

            td {
                color: white;
            }
        }

        .group2-row {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('grand-total-bg');
            }

            td {
                font-weight: bold;
                color: #7e94ac;
            }
        }

        .group2b-row {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('grand-total-bg');
            }

            td {
                color: #000;
            }
        }

        .group3-row {
            background-color: #fff;

            td {
                color: #000;
            }
        }

        .light-gray-col {
            background-color: lightgray !important;
        }

        .minor-group-row,
        .minor-group-cell {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('minor-group-row-cell');
            }
        }

        .minor-group2-row,
        .minor-group2-cell {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('minor-group2-row-cell');
            }
        }

        .minor-group3-row,
        .minor-group3-cell {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('minor-group3-row');
            }

            text-align: left;
        }

        .minor-group4-row,
        .minor-group4-cell {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('gray-cell');
            }
        }

        .banded-cell {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('banded-cell');
            }
        }

        .pl-account-indent-0 {
            text-align: left !important;
            padding-left: 5px;
        }

        .pl-account-indent-1 {
            text-align: left !important;
            padding-left: 15px;
        }

        .pl-account-indent-2 {
            text-align: left !important;
            padding-left: 25px;
        }

        .pl-account-indent-3 {
            text-align: left !important;
            padding-left: 35px;
        }

        .group-row,
        .group2-row,
        .group2b-row,
        .group3-row,
        .minor-group-row,
        .minor-group2-row,
        .minor-group3-row,
        .minor-group4-row {
            .l-c,
            .l-c-left,
            .l-c-indent,
            .var-cell {
                background-color: transparent;
            }
        }

        .group-row td,
        .group2-row,
        .group2b-row td,
        .group3-row td,
        .no-bottom-border {
            border-bottom: none;
        }

        .group-row td,
        .bottom-border {
            border-bottom: 1px solid #7e94ac;
        }

        .group2-row,
        .group2b-row td,
        .group3-row td {
            border-bottom: 1px solid #7e94ac;
            border-top: 1px solid #7e94ac;
        }

        .bottom-border {
            border-bottom: 1px solid #7e94ac;
        }

        .top-border {
            border-bottom: 1px solid #7e94ac;
        }

        .min-width {
            min-width: 100px;
        }

        .right-border {
            border-right: 1px solid #7e94ac;
        }

        .no-wrap {
            white-space: nowrap;
        }

        .right-border-solid {
            border-right: 3px solid #7e94ac;
        }

        .right-border-white {
            border-right: 2px solid #ffffff;
        }

        .expandable {
            cursor: pointer;
        }

        td.n-c {
            @include color-themes.themed('table') {
                color: color-themes.get-theme-color('cell-color');
            }
        }

        .n-c.red,
        .var-cell.red {
            @include color-themes.themed() {
                color: color-themes.get-theme-color('text-red') !important;
            }
        }

        .n-c.green,
        .var-cell.green {
            @include color-themes.themed() {
                color: color-themes.get-theme-color('text-green') !important;
            }
        }

        .bold {
            font-weight: 800;
        }

        .e-c {
            width: 2px !important;
            min-width: 2px !important;
            border: none;
            padding: 0 !important;

            @include color-themes.themed() {
                background-color: color-themes.get-theme-color(
                    'bg-color-lighter'
                ) !important;
            }
        }

        .arrowCell i {
            float: left;
        }

        tr.hidden {
            display: none;
        }

        .pl-title {
            font-weight: bold;
            font-variant-caps: all-small-caps;
            font-size: 14px;
            text-align: center;
        }

        &.table-hover {
            tbody {
                /* hover styling */
                tr:hover {
                    .var-cell {
                        background-color: transparent;
                    }

                    .l-c-left {
                        @include color-themes.themed('dx-pivot-table') {
                            background-color: color-themes.get-theme-color(
                                'table-row-highlight'
                            ); //rgba(126, 147, 172, 0.2);
                        }
                    }

                    .l-c-indent {
                        @include color-themes.themed('dx-pivot-table') {
                            background-color: color-themes.get-theme-color(
                                'table-row-highlight'
                            ); //rgba(126, 147, 172, 0.2);
                        }
                    }

                    .minor-group-cell {
                        @include color-themes.themed('table') {
                            background-color: color-themes.get-theme-color(
                                'minor-group-cell'
                            );
                        }
                    }

                    .minor-group2-cell {
                        background-color: #dee5eb;
                    }

                    .minor-group3-cell {
                        background-color: #e3e5e6;
                    }

                    .minor-group4-cell {
                        background-color: rgb(189, 189, 189);
                    }

                    .banded-cell {
                        background-color: rgba(0, 0, 0, 0.075);
                    }

                    .light-gray-col {
                        background-color: rgb(189, 189, 189) !important;
                    }
                }

                tr:hover.minor-group-row {
                    @include color-themes.themed('table') {
                        background-color: color-themes.get-theme-color(
                            'minor-group-row-hover'
                        );
                    }
                }

                tr:hover.minor-group2-row {
                    @include color-themes.themed('table') {
                        background-color: color-themes.get-theme-color(
                            'minor-group2-row-hover'
                        );
                    }
                }

                tr:hover.minor-group3-row {
                    @include color-themes.themed('table') {
                        background-color: color-themes.get-theme-color(
                            'minor-group3-row-hover'
                        );
                    }
                }

                tr:hover.minor-group4-row {
                    @include color-themes.themed('table') {
                        background-color: color-themes.get-theme-color(
                            'minor-group4-row-hover'
                        );
                    }
                }

                tr:hover.group-row,
                tr:hover.group2-row,
                tr:hover.group2b-row,
                tr:hover.group3-row,
                tr:hover.minor-group-row,
                tr:hover.minor-group2-row,
                tr:hover.minor-group3-row,
                tr:hover.minor-group4-row {
                    .l-c,
                    .l-c-left,
                    .var-cell {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    #zoom {
        position: absolute;
        padding-top: 10px;
        display: grid;
        gap: 5px;
    }

    .text-align-center {
        thead tr th,
        tbody tr td {
            text-align: center;
        }
    }

    .filled {
        width: 100%;
        height: 100%;
    }

    .pad10 {
        padding: 10px;
    }

    .btn:disabled {
        cursor: default;
    }

    .back-div {
        display: flex;

        span {
            margin: 11px;
        }
    }

    .topflop-header {
        height: 40px;

        ul {
            float: right;
            margin-top: 4px;
            margin-bottom: 0px;
        }
    }

    .dx-box-item-alignment {
        font-size: 14px;
        font-weight: bold;
        color: #7e94ac;
        padding: 10px 0px 20px;
        text-align: center;
    }

    .input-group-addon {
        i {
            position: relative;
            top: 8px;
        }
    }

    .subtext-div {
        position: relative;
        width: 100%;

        span {
            float: right;
            color: lightgray;
            font-style: oblique;
        }
    }

    .combo-picker {
        margin-right: 10px;
    }

    .combo-year {
        width: 100px !important;
    }

    .switch-container {
        .dx-switch {
            float: left;
            margin-left: 10px;
        }
    }

    .switch-container.inside-inline {
        .dx-field-label {
            padding-top: 6px;
            min-width: 0;
        }

        .dx-switch {
            margin-top: 4px;
        }
    }

    .label-dx-drop-down-box {
        height: 36px;
        margin-right: 3px;

        span {
            position: relative;
            top: 5px;
        }
    }

    .gap-row {
        height: 0;

        td {
            border-top: 1px solid #677d8c !important;
            border-bottom: none;
        }
    }

    .margin-sides {
        margin: 0px 20px;
    }

    // RUDI STYLING
    .dx-field-value {
        width: auto;
    }

    .dx-field-label {
        min-width: 90px;
    }

    .m-portlet .m-portlet__body {
        padding: 10px 20px 10px 20px;
    }

    .m-widget14 {
        padding: 10px 0px 0px 10px;
    }

    table.dataTable thead th,
    table.dataTable thead td {
        border-bottom: 0px;
        padding: 0px 5px !important;
        // background-color: #efefef;
        color: variables.$app-primary;
        font-weight: bold;
        text-align: center;
        border-bottom: 1px solid variables.$app-primary;

        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('bg-header-cell');
        }
    }

    table.dataTable tfoot th {
        border-bottom: 0px;
        padding: 0px 5px !important;

        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('footer');
        }

        color: variables.$app-primary;
        font-weight: bold;
        text-align: center;
        border-top: 1px solid variables.$app-primary;
    }

    table.dataTable thead th.emptyColumn,
    table.dataTable thead td.emptyColumn {
        background-color: #fff;
        border-bottom: none;
        border-top: none;
    }

    .m-subheader {
        padding: 5px 5px 15px 5px;

        .m-subheader__title {
            @include color-themes.themed() {
                color: color-themes.get-theme-color('text') !important;
            }
        }
    }

    .m-widget14 {
        padding: 10px 0px 0px 10px;
    }

    .m-widget__header_box {
        width: 100%;
    }

    .m-widget14__header {
        display: inline-flex;
        width: 100%;
    }

    .m-portlet {
        border-radius: 10px;

        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter') !important;
        }
    }

    table:not(.mat-calendar-table) td:not(.mat-column-CLMNPAD):not(.mat-column-CLMNPADTOTAL),
    table:not(.mat-calendar-table) th:not(.mat-column-CLMNPAD):not(.mat-column-header-row):not(.mat-column-CLMNPADTOTAL) {
        text-align: center !important;

        @include color-themes.themed('dx-pivot-table') {
            border-bottom: 1px dashed color-themes.get-theme-color('table-row-border') !important;
        }
    }

    th.mat-column-header-row {
        background-color: white !important;
        font-weight: bold;
        border-bottom: none!important;
        padding: 0 !important;
        height: 50px;
    
    }

    tr {
        height: 28px;
    }

    /*
    table.dataTable thead th:first-child {
        border-radius: 5px 0px 0px 5px;
        border: 1px solid #efefef !important;
    }
    table.dataTable thead th:last-child {
        border-radius: 0px 5px 5px 0px;
        border: 1px solid #efefef !important;
    }
    */
    .commentArea {
        width: 100%;
        background: #efefef;
    }

    .labelButton {
        background-color: #fff;
        border: 1px #efefef solid;
        width: 100%;
        cursor: pointer;
    }

    .labelButton:hover {
        background-color: variables.$app-primary;
    }

    td.highlight {
        border-left: 1px dashed variables.$app-primary;
        border-right: 1px dashed variables.$app-primary;
    }

    .labelField {
        text-align: left !important;
        font-weight: 500;
        line-height: 24px;
        // display: inline-flex;
        width: 100px !important;
    }

    .numberField {
        text-align: center !important;
        font-weight: 500;
        line-height: 24px;
        width: 70px;
    }

    .barChart_td {
        height: 24px;
        width: 80px;
    }

    .barChart_div {
        height: 24px;
        width: 100%;
    }

    .barInside {
        display: block;
        height: 24px;
        background-color: #dbe3e8;
        top: 0;
        left: 0;
        position: relative;
    }

    .barLabel {
        display: block;
        position: relative;
        text-align: center;
        width: 100%;
        top: -24px;
    }

    td.varColumn {
        background-color: #f8f8f8;
        width: 80px !important;
    }

    .tooltip-name {
        font-weight: bold;
        font-size: 18px;
        color: variables.$app-primary;
    }

    .tooltip-serie {
        font-weight: bold;
        font-size: 12px;
    }

    // Card Base
    .card {
        border: 1px solid transparent;
        border-radius: 10px;

        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter');
        }
    }

    .card.card-custom {
        border: 0;
        margin-bottom: 15px;

        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter') !important;
        }

        // Header
        > .card-header {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
            //height: $card-header-height;
            min-height: 60px;
            padding-top: 0;
            padding-bottom: 0;
            background-color: transparent;

            // Title
            .card-title {
                display: flex;
                align-items: center;
                margin: 0.5rem;
                margin-bottom: 0.5rem !important;
                margin-left: 0;
                //flex-wrap: wrap;

                .card-icon {
                    margin-right: 0.75rem;
                    line-height: 0;

                    i {
                        font-size: 1.25rem;
                        color: #565962;
                        line-height: 0;

                        &:after,
                        &:before {
                            line-height: 0;
                        }
                    }

                    /* .svg-icon {
                        @include svg-icon-size(24px);
                        @include svg-icon-color($dark-50);
                    } */
                }

                &,
                .card-label {
                    font-weight: 500;
                    font-size: 1.275rem;

                    @include color-themes.themed() {
                        color: color-themes.get-theme-color('text');
                    }
                }

                .card-label {
                    margin: 0 0.75rem 0 0;
                    flex-wrap: wrap;
                }

                // Description
                small {
                    color: #ff9900;
                    font-size: 1rem;
                }
            }

            // Toolbar
            .card-toolbar {
                display: flex;
                align-items: center;
                margin: 0.5rem 0;
                flex-wrap: wrap;
                gap: 10px;
            }

            // Text Muted
            .text-muted {
                color: #b78a03 !important;
            }

            // Line tabs integration
            &.card-header-tabs-line {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
                align-items: stretch;

                .card-toolbar {
                    margin: 0;
                }

                .nav {
                    border-bottom-color: transparent;

                    .nav-item {
                        align-items: stretch;
                    }

                    .nav-link {
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }
            }

            &.card-header-right {
                justify-content: flex-end;
            }
        }

        // Body
        > .card-body {
            padding: 1rem 1.25rem !important;
            border: 0px solid transparent !important; //padding: $card-spacer-y $card-spacer-x;
        }

        // Footer
        > .card-footer {
            background-color: transparent;
        }

        // Scroll
        .card-scroll {
            position: relative;
            overflow: auto;
        }

        // Make full height
        &.card-stretch {
            display: flex;
            align-items: stretch !important;
            flex-direction: column;
            height: 100%;

            &.gutter-b {
                height: calc(100% - 10px);
            }

            &.card-stretch-half {
                height: 50%;

                &.gutter-b {
                    height: calc(50% - 10px);
                }
            }

            &.card-stretch-third {
                height: 33.33%;

                &.gutter-b {
                    height: calc(33.33% - 10px);
                }
            }

            &.card-stretch-fourth {
                height: 25%;

                &.gutter-b {
                    height: calc(25% - 10px);
                }
            }
        }

        // Card header fit style
        &.card-fit {
            > .card-header {
                border-bottom: 0;
            }

            > .card-footer {
                border-top: 0;
            }
        }

        // Card space style
        &.card-space {
            padding-left: 5px;
            padding-right: 5px;

            > .card-header {
                padding-left: 0;
                padding-right: 0;
            }

            > form > .card-body,
            > .card-body {
                padding-left: 0;
                padding-right: 0;
            }

            > form > .card-footer,
            > .card-footer {
                padding-left: 0;
                padding-right: 0;
            }
        }

        // Sticky card
        &.card-sticky {
            > .card-header {
                transition:
                    left 0.3s,
                    right 0.3s,
                    height 0.3s;
                //height: $card-sticky-header-height;
                min-height: 40px;
            }
        }

        // Transparent Background
        &.card-transparent {
            background-color: transparent;
        }

        // No Shadow
        &.card-shadowless {
            box-shadow: none;
        }

        // Reset Padding X
        &.card-px-0 {
            .card-header,
            .card-body,
            .card-footer {
                padding-left: 0;
                padding-right: 0;
            }
        }

        // Border style
        &.card-border {
            box-shadow: none;
            border: 1px solid #fff;
        }

        // Collapsed Mode
        &.card-collapsed {
            > form,
            > .card-body {
                display: none;
            }
        }
    }

    #cardLoader mat-progress-bar {
        width: calc(100% + 12px);
        margin: auto;
        margin-bottom: auto;
        margin-top: 0;
        top: -14px;
        left: -6px;
    }

    #cardLoader .icon-no-data {
        display: none;

        align-items: center;
        margin: auto;
        font-size: 25px;
        color: #ccc;
    }

    #cardLoader.noData {
        /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
        mat-progress-bar {
            display: none;
        }

        .icon-no-data {
            display: flex;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
    #cardLoader .mat-progress-bar-buffer {
        @include color-themes.themed('mat-resets') {
            background-color: color-themes.get-theme-color('progress-bar-bg') !important;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
    #cardLoader .mat-progress-bar-fill::after {
        @include color-themes.themed('mat-resets') {
            background-color: color-themes.get-theme-color(
                'progess-bar-fill-after'
            ) !important;
        }
    }

    .hide-overlay:not(.noData) {
        opacity: 0;
        display: none !important;
    }

    .font-size-sm {
        font-size: 0.925rem;
    }

    .mt-0 {
        margin-top: 0rem;
    }

    .card-loader {
        position: absolute;
        display: flex;
        align-items: center;
        text-align: center;
        height: calc(100% - 1.8rem);
        width: calc(100% - 2.5rem);
        border-radius: 5px;
        z-index: 100;
        opacity: 1;
        transition: opacity 0.5s ease;

        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter');
            border: 1px solid color-themes.get-theme-color('bg-color-lighter');
        }
    }

    .mat-mdc-icon-button.btntree {
        height: 24px;
        width: 24px;
        line-height: 24px;
        float: left;
    }

    .mat-mdc-cell.kpi-score {
        text-align: left !important;
        font-size: 40px !important;
        font-weight: 600;
        color: #b18605;
        padding: 15px !important;
        line-height: 50px;
    }

    .mat-mdc-cell.kpi-var {
        font-size: 20px !important;
        width: 50%;
        text-align: left !important;
        //width: 60px;
        font-weight: 700;
        line-height: 40px;
    }

    .mat-mdc-cell.kpi-var-label {
        color: variables.$app-primary;
        font-size: 15px !important;
        width: 50%;

        font-weight: 600;
        line-height: 40px;
    }

    .force-radius-right {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .echart_tooltip_header {
        background: variables.$app-primary;
        color: #fff;
        text-align: center;
        width: 100%;
        padding: 0px 5px;
        border-radius: 5px;
        font-weight: 800;
    }

    .card-body-header h3.__title,
    .card-body-header-middle h3.__title {
        text-align: center;
        color: #b18605;
        line-height: 1.2;
        font-size: 13px;
        margin-bottom: 0.5rem;
    }

    .card-body-header .__desc,
    .card-body-header-middle .__desc {
        text-align: center;
        width: 100%;
    }

    .card-header.greyOut {
        background-color: #f8f8f8 !important;
        opacity: 0.5;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
        background-color: #b18605 !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
        background-color: rgba(#b18605, 0.54) !important;
    }

    .tot-header-row {
        background-color: #f8f8f8;

        th.mat-mdc-header-cell {
            background-color: transparent;
        }

        div.chart-container {
            width: 50%;
            height: 50px;
        }

        div.sum-total {
            font-size: 16px;
            line-height: 16px;
        }

        div.ratio-container {
            width: 100%;
            padding: 10px 5px;
        }

        .header-chart {
            height: calc(100% - 10px);
            width: 100px;
            margin-left: auto;
            margin-right: auto;
        }

        .header-chart-desc {
            font-size: 10px;
            text-align: left;
            width: 100px;
            margin-left: auto;
            margin-right: auto;
            color: #b08605;
        }
    }

    .mat-mdc-cell {
        /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
        .mat-progress-bar-buffer {
            background-color: #fff;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
        .mat-progress-bar-fill::after {
            background-color: #b487031a;
        }

        .mat-mdc-progress-bar {
            height: 18px;
        }

        .progress-bar-label {
            position: absolute;
            font-size: 14px;
            z-index: 1;
            height: 28px;
            display: flex;
            align-items: center;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
        }

        .progress-bar-label-mape {
            position: absolute;
            font-size: 14px;
            z-index: 1;
            height: 28px;
            display: flex;
            align-items: center;
            top: 0px;
            left: 24px;
            color: white;
            font-weight: 700;
            transform: translateX(-50%);
        }

        .progress-bar-achv {
            position: absolute;
            color: #ccc;
            z-index: 2;
            height: 28px;
            display: flex;
            align-items: center;
            top: 0px;
            right: 15px;
        }
    }

    .mat-mdc-cell.progress-bar-td {
        position: relative;
    }

    .text-capitalize {
        text-transform: capitalize !important;
    }

    brandcomdeviceperfwidget .mat-column-label {
        max-width: 200px;
        width: 200px;
    }

    .top-dashboard-info {
        margin-left: 25px;
        margin-bottom: 5px;
        color: #b08605;
    }

    .no-data-section-rb {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        float: right;
        z-index: 100;
        position: absolute;
        background: #fff;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-no-header .mat-mdc-tab-header {
        display: none;
    }

    .highlight-success {
        font-weight: 600;
        color: #b08605 !important;
    }

    .treeLevel0,
    .treeLeveltrue {
        font-weight: 700;

        td {
            @include color-themes.themed('table') {
                color: color-themes.get-theme-color('tree-level0-color');
            }
        }

        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('tree-level0-bg');
            border-top: 1px solid color-themes.get-theme-color('tree-level0-color');
            border-bottom: 1px solid color-themes.get-theme-color('tree-level0-color');
        }
    }

    .treeLevel1 {
        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('tr-cell-bg');
        }
    }

    .treeLevel2 {
        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('bg-tree-level-2') !important;
        }
    }

    .treeLevel5 {
        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('tr-cell-bg');
        }
    }

    .col-highlight-gray {
        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('highlight-row') !important;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.*/
    .mat-menu-dropdown {
        position: relative;

        button.mat-mdc-button {
            border: 1px solid variables.$app-primary;
            color: variables.$app-primary;
            height: 37px;
            line-height: 26px;
        }
    }

    .echartSlideHeight {
        height: calc(100% - 80px) !important;
    }

    brandcomvisityoywidget,
    brandcommapwidget {
        height: 100%;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-body-wrapper {
        height: 100%;
    }

    .map-container {
        width: 100% !important;
    }

    .mat-icon-navi-border {
        border: 1px solid #b38703;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        font-size: 15px;
        line-height: 16px !important;
    }

    app-period-selector .mat-menu-dropdown button.mat-mdc-button,
    app-period-selector-selectedyear .mat-menu-dropdown button.mat-button {
        border: none !important;
    }

    app-period-selector-next12months .mat-menu-dropdown button.mat-button {
        border: none !important;
    }

    app-period-selector .mat-mdc-icon-button,
    app-period-selector-selectedyear .mat-icon-button {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }

    app-period-selector-next12months .mat-icon-button {
        width: 20px;
        height: 20px;
        line-height: 20px;
    }

    .header-content-center {
        display: flex;
        justify-content: center;
    }

    @media (min-width: 1400px) {
        .row.row-col-separator-xl > div {
            border-bottom: 0;
            border-right: 1px solid #ebedf2;
        }

        .row.row-col-separator-xl > div:last-child {
            border-right: 0;
        }
    }

    @media (min-width: 1025px) {
        .row.row-col-separator-lg > div {
            border-bottom: 0;
            border-right: 1px solid #ebedf2;
        }

        .row.row-col-separator-lg > div:last-child {
            border-right: 0;
        }
    }

    .form_input {
        border-color: transparent;
        border: 1px solid #ccc;
        border-radius: 3px;
        text-align: center;
        width: 60px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-ok {
        background-color: #a4cb1773;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-alert {
        background-color: tomato;
    }

    .card-body-on-slide {
        overflow: auto;
        margin-bottom: 10px;
    }

    .card-container-on-slide {
        max-height: 80vh;
    }

    @media (max-width: 1440px) {
        .card-container-on-slide {
            max-height: 70vh;
        }
    }

    .hoth-list-timeline .hoth-list-timeline__items {
        position: relative;
        padding: 0;
        margin: 0;
    }

    .hoth-list-timeline .hoth-list-timeline__items .hoth-list-timeline__item {
        table-layout: fixed;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0.3rem 0;
        margin: 0.5rem 0;
    }

    .hoth-list-timeline
        .hoth-list-timeline__items
        .hoth-list-timeline__item
        .hoth-list-timeline__badge:before {
        background-color: #ebedf2;
        position: absolute;
        display: block;
        content: '';
        width: 7px;
        height: 7px;
        left: 0;
        top: 50%;
        margin-top: -3.5px;
        border-radius: 100%;
    }

    .hoth-list-items {
        margin: 0 20px 0 0;
        font-size: 1.2rem;
        color: #e3a32a;
        display: flex;
        vertical-align: middle;
        display: table-cell;
        line-height: 1.2rem;
        font-weight: 500;
        text-transform: capitalize;
    }

    .hoth-list-timeline
        .hoth-list-timeline__items
        .hoth-list-timeline__item
        .hoth-list-timeline__badge {
        text-align: left;
        vertical-align: middle;
        display: table-cell;
        position: relative;
        width: 20px;
    }

    .hoth-list-timeline
        .hoth-list-timeline__items
        .hoth-list-timeline__item
        .hoth-list-timeline__badge.hoth-list-timeline__badge--primary:before {
        background-color: #efefef;
    }

    .hoth-separator.hoth-separator--border-dashed,
    .hoth-separator.hoth-separator--dashed {
        border-bottom: 1px dashed #ebedf2;
    }

    .hoth-separator {
        height: 0;
        margin: 20px 0;
        border-bottom: 1px solid #ebedf2;
    }

    .pos-container {
        height: fit-content;
        overflow: auto;

        border-radius: 5px;
        padding: 10px 5px 10px 5px;
        background-color: #f8f8f8;
    }

    .fs-22 {
        font-size: 22px;
    }

    // ADMIN MAPPING CSS
    .isFolder .node-container {
        border: 1px dashed variables.$app-primary;
        padding: 0px 10px;
        margin: 5px 20px 5px 5px;
        border-radius: 3px;
    }

    .isFolder .mat-mdc-icon-button {
        color: variables.$app-primary !important;
    }

    .isFolder .node-title {
        color: variables.$app-primary;
        font-weight: 600;
    }

    .isFolder .account_count {
        background-color: variables.$app-primary;
    }

    .isFolder .node-container:hover {
        border: 2px solid variables.$app-primary;
    }

    .isLeaf .node-container {
        border: 1px dashed #bf9000;
        margin: 5px 20px 5px 5px;
        padding: 0px 10px;
        border-radius: 2px;
    }

    .isLeaf .node-container:hover {
        border: 2px solid #bf9000;
    }

    .isLeaf .mat-mdc-icon-button {
        color: #bf9000 !important;
    }

    .isLeaf .node-title {
        color: #bf9000;
        font-weight: 600;
    }

    .isLeaf .account_count {
        background-color: #bf9000;
    }

    .oldItem {
        background-color: rgba(#bf9000, 0.2) !important;
    }

    .leaf-item {
        padding: 5px 10px;
        border-bottom: solid 1px #ccc;
        color: rgba(0, 0, 0, 0.87);
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        border-radius: 5px;
        font-size: 12px;
        margin: 5px;

        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter');
        }
    }

    .leaf-item:hover {
        background-color: #bf9000 !important;
    }

    .isLeaf .account_count.not_empty {
        background-color: red;
        color: #fff;
    }

    .isFolder .account_count.not_empty {
        background-color: variables.$app-primary;
        color: #fff;
    }

    .search_header {
        position: relative;
        display: flex;
        z-index: 1;
        padding: 0px 10px;
        align-items: baseline;
    }

    .search_header:after {
        box-shadow: 0px 17px 15px -15px rgb(170 170 170 / 30%);
        content: ' ';
        height: 100%;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .mat-tree-container {
        height: calc(100% - 60px);
        overflow: auto;
        padding-top: 10px;
        border-radius: 5px;
        background-color: #f8f8f8;
    }

    .mat-tree {
        background-color: transparent !important;
    }

    .panel_footer {
        position: relative;
        padding: 10px 0px;
        z-index: 0;
        display: flex;
        gap: 5px;
        justify-content: right;
        height: 60px;
    }

    .search_footer {
        position: relative;
        padding: 10px 0px;
        z-index: 0;
        display: flex;
        gap: 5px;
        justify-content: right;
        height: 60px;
    }

    .search_footer:before {
        box-shadow: 0px -17px 15px -15px rgb(170 170 170 / 30%);
        content: ' ';
        height: 40px;
        position: absolute;
        z-index: -1;
        top: 0px;
        width: 100%;
    }

    .mat-tree-admin-mode {
        cursor: move;
    }

    .out-list.missing_container {
        height: 100%;
        background-color: #f8f8f8;
        overflow: auto;
        border-radius: 5px;
        padding: 10px 5px;
    }

    .out-list.missing_container:hover {
        background-color: #efefef;
    }

    .cockpit-buckets {
        width: 200px;
        height: 100px;
        border: 1px solid variables.$app-primary;
        border-radius: 5px;
        padding: 10px;
        margin: 5px;
    }

    .cockpit-buckets:hover {
        background: #efefef;
        cursor: pointer;
    }

    .prod_bucket_name {
        text-align: center;
        margin-bottom: 20px;
    }

    .prod_bucket_kpi {
        text-align: center;
        display: inline-flex;
        width: 100%;

        span.label {
            display: block;
            width: 400px;
            text-align: right;
        }

        span.kpi {
            display: block;
            width: 100%;
            text-align: center;
        }
    }

    .prod_bucket_kpi.var {
        border-top: 1px solid #efefef;
        margin-top: 2px;
        margin-bottom: 5px;
    }

    .btn-success:focus,
    .btn-success.focus {
        color: #fff !important;
        background-color: #b48707 !important;
        box-shadow: 0 0 0 0.2rem rgb(178 135 4 / 0%);
    }

    .highlight-we {
        background-color: #b5880217 !important;
    }

    .cockpit-line {
        border-top: 1px solid #b48707;
        color: #b48707 !important;
        font-weight: 500;
    }

    .cockpit-line-con {
        color: #b48707 !important;
        font-weight: 500;
    }

    .td-prod-hours {
        border-top: 1px solid #b48707;
        color: #b48707 !important;
    }

    .td-non-prod-hours {
        border-top: 1px solid #b48707;
        color: #b48707 !important;
    }

    .tablemenu-hidden {
        display: none;
    }

    .tablemenu {
        height: calc(100% - 56px);
        position: absolute;
        top: 40px;

        .col-border-right {
            border-right: 0px !important;
        }

        .hg-right-b {
            border-right: 0px !important;
        }
    }

    .pms-highlight-day {
        background-color: #7e94ac1f !important;
        font-weight: 600;
        border-left: 2px solid #0ab1a5;
        border-right: 2px solid #0ab1a5;
    }

    th.pms-highlight-day {
        background-color: #05b1a5 !important;
        color: white !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    app-menu-date-picker .mat-form-field-wrapper {
        padding: 0;
        border: 1px solid variables.$app-primary;
        border-radius: 5px;

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-underline {
            display: none;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    app-menu-date-picker .mat-form-field-label-wrapper {
        display: none;
    }

    app-menu-date-picker .mat-form-field-appearance-fill {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-flex {
            padding: 0;
            border-radius: 4px 4px 4px 4px;
            align-items: center !important;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
            padding: 0;
            border: 0;
            width: 120px;

            .mat-datepicker-input {
                color: variables.$app-primary;
                font-weight: 500;
            }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-flex {
            padding-left: 15px;

            @include color-themes.themed() {
                background-color: color-themes.get-theme-color('bg-color-lighter');
            }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-suffix .mat-mdc-icon-button {
            height: 29.5px;
        }
    }

    .weekHighlight {
        font-weight: 800;
        font-size: 14px;
    }

    .row.row-col-separator-lg > div {
        border-bottom: 0;
        border-right: 1px solid #ebedf2;
    }

    .mat-mdc-table .mat-icon {
        position: relative;
        bottom: 8px;
        right: 10px;
    }

    .mat-mdc-table tr td:first-child div {
        line-height: 30px !important;
    }

    .mat-mdc-table .btn-expand-all {
        position: relative;
        top: 5px;
    }

    .cockpit-sub-item
        mat-form-field
        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: inherit;
    }

    .cockpit-group-item
        mat-form-field
        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: inherit;
    }

    app-productivity-drivers.cost-driver-menu button {
        background-color: #7e94ac !important;
    }

    app-productivity-drivers.cost-driver-menu {
        width: 100%;
    }

    app-prod-driver-item div.cockpit-label {
        width: 200px;
        font-size: 1.2rem;
    }

    app-prod-driver-item.indent1 div.cockpit-label {
        width: 295px;
        font-size: 1.2rem;
    }

    app-prod-driver-item.indent2 div.cockpit-label {
        width: 285px;
        font-size: 1.2rem;
    }

    app-prod-driver-item.indent3 div.cockpit-label {
        width: 275px;
        font-size: 1.2rem;
    }

    app-prod-driver-item.indent4 div.cockpit-label {
        width: 265px;
        font-size: 1.2rem;
    }

    [data-indent='0'] {
        padding-left: 0;
    }

    [data-indent='1'] {
        padding-left: 10;
    }

    [data-indent='2'] {
        padding-left: 20;
    }

    [data-indent='3'] {
        padding-left: 30;
    }

    app-menu-date-picker .mat-mdc-form-field-infix {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
    }

    app-menu-date-picker .mat-mdc-form-field-icon-suffix {
        top: -8px !important;
    }

    #overbookingTable td.mat-column-label {
        max-width: 150px;
        min-width: 150px;
        width: 150px;
    }

    .driver-settings-form, .cockpit-allocation-dialog {
        .mat-mdc-text-field-wrapper,
        .mdc-text-field--filled {
            height: 56px !important;
        }
    }

    .mat-mdc-table-sticky-border-elem-left:after {
        width: 15px;
        box-shadow: 15px 0 15px -15px inset #0003;
        right: -15px;
    }

    .mat-mdc-table-sticky:after {
        content: ' ';
        height: 100%;
        position: absolute;
        top: 0;
    }

    .mat-mdc-table-sticky-border-elem-right:before {
        width: 15px;
        box-shadow: -15px 0 15px -15px inset #0003;
        left: -15px;
    }

    .mat-mdc-table-sticky:before {
        content: ' ';
        height: 100%;
        position: absolute;
        top: 0;
    }

    .staticForecastForm .mdc-text-field--filled {
        height: 56px !important;
    }

    .calendar-no-header > mat-calendar-header {
        display: none !important;
    }

    .full-width {
        width: 100%;
    }

    .full-width .mat-mdc-form-field-infix {
        min-height: auto !important;
        height: auto !important;
        width: 150px !important;
    }

    .displacement-input {
        margin: 3px;
    }

    .displacement-input input {
        text-align: center !important;
    }

    .b-m-10 {
        margin-bottom: 10px;
    }

    mat-form-field.no-hint > div.mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }

    mat-form-field.no-hint.h-40 {
        height: 40px;

        div.mat-mdc-form-field-infix {
            height: 40px;
            padding: 8px;
        }

        div.mdc-line-ripple {
            display: none;
        }
    }

    .stepper-btn {
        background-color: #00bcd4;
        color: white !important;
    }

    mat-step-header:hover {
        border-radius: 10px !important;
        background-color: #00bcd42b;
        color: white !important;

        div.mat-step-icon:not(.mat-step-icon-state-error) {
            background: #00bcd4;
            color: white;
        }
    }

    mat-step-header {
        div.mat-step-icon:not(.mat-step-icon-state-error) {
            background: #00bcd4;
            color: white;
        }
    }

    .displacement-tool {
        .mat-pseudo-checkbox {
            color: white !important;
        }
    }

    .bg-green-1 {
        background-color: #00660070;
    }

    .bg-green-2 {
        background-color: #17750070;
    }

    .bg-green-3 {
        background-color: #35850070;
    }

    .bg-green-4 {
        background-color: #59940070;
    }

    .bg-green-5 {
        background-color: #83a30070;
    }

    .bg-yellow-1 {
        background-color: #b3b30070;
    }

    .bg-yellow-2 {
        background-color: #c29b0070;
    }

    .bg-orange-1 {
        background-color: #d17d0070;
    }

    .bg-orange-2 {
        background-color: #e05a0070;
    }

    .bg-red-1 {
        background-color: #f0300070;
    }

    .bg-red-2 {
        background-color: #ff000070;
    }

    .bg-grey {
        background-color: #efefef;
    }

    .b-r-100 {
        border-radius: 100%;
    }

    .selected-date {
        background-color: #00bcd4;
        border: 5px solid white;
        border-style: double;
        border-radius: 50%;
        color: white;
    }

    .displacement-tool .mat-mdc-form-field {
        flex-direction: column !important;
    }

    .displacement-tool-tab-menu .mat-mdc-tab-header {
        background-color: #efefef;
        border-radius: 5px;
    }

    .events .mat-tooltip {
        white-space: pre-line;
        /* Apply only to tooltips within the "events" class */
    }

    .mapping-form {
        .mat-mdc-form-field {
            display: inline-flex;
            flex-direction: column !important;
            width: 100%;
        }

        .mat-mdc-text-field-wrapper,
        .mdc-text-field--filled {
            height: 56px !important;
        }
    }

    app-checkbox-menu-collapsible {
        .mat-icon {
            position: static !important;
        }
    }

    .menu-collapsible .mat-mdc-menu-content {
        max-height: 300px !important;
        overflow-y: auto;
        /* Optional: Add scroll if content exceeds max-height */
    }

    .modal-header {
        padding: 15px 15px 0px 15px;
        border-bottom: 1px solid #efefef;
    }

    .modal-title {
        font-weight: bold;
        line-height: 38px;
        font-size: 20px;
    }
    // END OF RUDI'S CSS

    // material-table.scss
    // EXTENSION MAT - TABLE BIRD
    // MAT TABLE CSS
    .example-custom-date-class {
        background: orange !important;
        border-radius: 100%;
    }

    .mat-calendar-date-class-highlight {
        border: 1px solid red;
        border-radius: 100%;
    }

    .text-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left !important;
        padding-left: 8px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-background {
        background-color: #b58802;
    }

    tr.mat-mdc-row,
    tr.mat-mdc-footer-row,
    mat-row,
    mat-footer-row {
        height: 28px !important;
        min-height: 28px !important;
    }

    .admin mat-row {
        height: 40px !important;
        min-height: 40px !important;
    }

    tr.mat-mdc-header-row,
    mat-header-row {
        height: 40px !important;
        min-height: 40px !important;
        border-bottom-width: 0px !important;
    }

    th.mat-mdc-header-cell:first-of-type,
    mat-header-cell:first-of-type {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    th.mat-mdc-header-cell:last-of-type,
    mat-header-cell:last-of-type {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    th.mat-mdc-header-cell,
    mat-header-cell {
        font-weight: 800;
        border-bottom-width: 0px !important;
        font-size: 12px;

        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('bg-header-cell');
            color: color-themes.get-theme-color('header-cell-color');
        }
    }

    td.mat-mdc-cell {
        min-width: 35px;

        @include color-themes.themed('table') {
            border-bottom: 1px dashed color-themes.get-theme-color('cell-border-color') !important;
        }
    }

    /*  td.mat-cell.STANDARD:not(.mat-column-COMMENTID){
        border-bottom: 1px dashed #efefef!important;
      } */

    .mat-mdc-cell {
        font-size: 0.9rem !important;
    }

    .text-green {
        @include color-themes.themed() {
            color: color-themes.get-theme-color('text-green') !important;
        }
    }

    .text-red {
        @include color-themes.themed() {
            color: color-themes.get-theme-color('text-red') !important;
        }
    }

    // .reset-color[theme-data="light"] {
    //     color: black !important;
    // }

    .text-dark-gray {
        color: #575962 !important;
    }

    .text-white {
        color: white !important;
    }

    .text-upper {
        text-transform: uppercase;
    }

    td.mat-mdc-cell:first-of-type,
    th.mat-mdc-header-cell:first-of-type {
        padding-left: 10px !important;
    }

    td.mat-mdc-cell:not(.mat-column-revpolu_gm):last-of-type,
    th.mat-mdc-header-cell:not(.mat-column-revpolu_gm):last-of-type {
        padding-right: 10px !important;
    }

    td.mat-mdc-cell.mat-column-CLMNPAD {
        border: none !important;
    }

    th.mat-column-CLMNPAD, th.mat-column-CLMNPADTOTAL {
        background-color: #ffffff !important;
        flex-direction: row;
        display: flex;
        height: 100%;
        width: 10px;
        max-width: 10px;
        padding: 0 !important;
        position: relative;
        top: -5px;
        border: none !important;
    }

    td.mat-column-CLMNPAD, td.mat-mdc-cell.mat-column-CLMNPAD, td.mat-column-CLMNPADTOTAL, td.mat-mdc-cell.mat-column-CLMNPADTOTAL {
        background-color: #fff !important;
        flex-direction: row;
        display: flex;
        width: 10px !important;
        max-width: 10px !important;
        min-width: 10px !important;
        padding: 0 !important;
        position: relative;
        border: none !important;
    }

    th.cdk-column-CLMNPAD:before {
        //content: ".";
        z-index: 1;
        color: #efefef;
        display: block;
        top: -5px;
        height: 100%;
        margin: 1px 4px 0px 0px;
        background-color: #efefef;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    td.cdk-column-CLMNPAD:before {
        content: ' ';
        width: 15px;
        z-index: 1;
        color: #ffffff;
        display: block;
        height: 30px;
        margin: 1px 0px 0px 0px;
        background-color: #ffffff;
    }

    th.cdk-column-CLMNPAD:after {
        //content: ".";
        z-index: 1;
        color: #efefef;
        display: block;
        height: 100%;
        margin: 1px 0px 0px 0px;
        background-color: #efefef;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    td.cdk-column-CLMNPAD:after {
        content: ' ';
        z-index: 1;
        color: #efefef;
        display: block;
        height: 30px;
        margin: 1px 0px 0px 0px;
        background-color: #efefef;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .mat-column-header-row-single-group,
    .mat-column-header-row-third-group,
    .mat-column-header-row-first-group,
    .mat-column-header-row-second-group,
    .mat-column-header-row-fourth-group,
    .mat-column-header-row-ytg-group,
    .mat-column-header-row-appendix,
    .mat-column-header-row-comment {
        font-weight: 600 !important;
        color: #b98d27 !important;
        font-size: 16px !important;
        z-index: 90 !important;
    }

    table {
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 12px;
    }

    table.dataTable.no-footer {
        border-bottom: none;
        border-top: none;
    }

    .dataTables_filter {
        display: table;
        margin-right: 10px;
        table-layout: fixed;
        text-align: right;
        border-radius: 30px;
        background-color: #b98d27;
        margin-bottom: 15px;
        padding-right: 10px;
    }

    .dataTables_filter label {
        height: 30px;
        color: #ffffff;
        text-align: left;
        padding-top: 8px;
        padding-left: 20px;
        vertical-align: middle;
    }

    .dataTables_filter input {
        border: 0;
        background: none;
        outline: none !important;
        box-shadow: none;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        font-size: 1rem;
        transition: width 0.4s ease-out;
        display: inline;
        color: #f8f8f8;
    }

    .btn-accent {
        color: #fff;
        background-color: #b98d27;
        border-color: #b98d27;
    }

    .PART .labelField {
        color: #ff9614;
        padding-top: 15px;
        font-weight: 800;
        font-size: 15px;
    }

    .PART td {
        border-bottom: none;
        padding-top: 20px !important;
        //border-bottom: solid 1px #b98d27;
        padding-bottom: 0p;
        font-size: 14px;
        color: #fff;
    }

    tr.mat-mdc-row.SPACE {
        height: 10px !important;
    }

    .SPACE td.mat-mdc-cell {
        height: 10px;
        line-height: 0px;
        border-bottom: none !important;
        color: #fff;
    }

    tr.TOPBOTTOMLINE td {
        border-top: 2px solid #b98d27;
        border-bottom: 2px solid #b98d27 !important;
        font-weight: 600;
        font-style: normal;
        font-size: inherit;
    }

    tr.TOPLINE td {
        border-top: 2px solid #b98d27;
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
    }

    .HIGHLIGHT {
        font-weight: 300;
        font-style: normal;
        font-size: 11px;
        color: #b98d27 !important;
    }

    .DROPTHROUGH {
        font-size: 11px;
        color: #fff !important;
    }

    .HIGHLIGHT > .labelField,
    .DROPTHROUGH > .labelField {
        color: #b98d27;
        font-size: 11px;
    }

    .DROPTHROUGH td {
        color: #fff;
    }

    .stat_val {
        font-size: 11px;
    }

    tr:not(.TITLE):not(.SUBTITLE) .var_val {
        width: 75px;
        font-size: 11px;
        border-left: 1px solid #efefef;
        background: #f7f7f780;
        border-right: 1px solid #efefef;
    }

    .var_val.var_free {
        font-size: 11px !important;
        font-style: italic;
        width: auto !important;
        border: 0px solid #fff !important;
        background: none !important;
    }

    .header-group {
        height: 28px !important;
    }

    thead:not(.mat-calendar-table-header) {
        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter');
        }
    }

    th {
        white-space: nowrap;
        border-color: transparent;
        padding: 0px 8px;

        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter');
        }
    }

    .mat-mdc-table th:first-child {
        text-align: left;
        padding-left: 5px;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        z-index: 0;
    }

    .mat-mdc-table th:last-child {
        text-align: center;
        padding-right: 10px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
    }

    tr.SUBTITLE {
        height: 42px !important;

        td:not(.mat-column-COMMENTID) {
            border-bottom: solid 1px #b98d27 !important;
            font-weight: bold;
        }

        td.labelField {
            color: #b98d27;
            font-weight: 800;
            text-align: left;
            min-width: 120px;
            max-width: 200px;
        }
    }

    tr.TITLE {
        height: 36px !important;

        td:not(.mat-column-COMMENTID) {
            border-bottom: solid 2px #7e94ac !important;
            font-weight: bold;
        }

        td.labelField {
            color: #7e94ac;
            font-size: 1.1rem !important;
            font-weight: 800;
            text-align: left;
            min-width: 120px;
            max-width: 200px;
        }
    }

    tr.STANDARD:not(.mat-column-COMMENTID),
    tr.FIXED:not(.mat-column-COMMENTID),
    tr.PERCENT:not(.mat-column-COMMENTID) {
        td:not(.mat-column-COMMENTID) {
            border-bottom: dashed 1px #efefef !important;
        }

        td.data {
            min-width: 70px;
            max-width: 90px;
        }
    }

    td.details-control {
        background-color: #fff;
        color: #012b41;
        cursor: pointer;
        text-align: left;
        width: 218px;
        min-width: 218px;
        max-width: 218px;
    }

    .t-c {
        text-align: center;
    }

    td.labelBreakdown {
        color: #012b41;
        cursor: pointer;
        text-align: right;
        width: 190px;

        max-width: 218px;
    }

    td.scnBreakdown {
        width: 80px;
    }

    td.varBreakdown {
        width: 50px;
    }

    tr.shown td.details-control {
        background-color: #fff;
        text-align: left;
        min-width: 218px;
        max-width: 218px;
    }

    div.slider {
        display: none;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .breakdown {
        background-color: #f8f8f8;
    }

    .child2 {
        font-style: italic;
        background-color: #fffdfd;
    }

    .commentBox {
        display: block;
        background-color: #e7eef5;
        border-radius: 8px;
        padding: 14px;
        text-align: left;
        width: 100%;
    }

    .NORMAL {
        font-weight: 300;
        font-style: normal;
        font-size: 12px;
    }

    .ITALIC {
        font-style: normal;
        font-weight: 300;
        font-size: 13px;
    }

    .BOLD {
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
    }

    .BOLDHIGHLIGHT,
    .BOLDTOTAL {
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        //background-color: #f8f8f8;
    }

    .BOLDHIGHLIGHT > .labelField {
        color: #d2ab77;
        //background-color: #b98d27;
    }

    .BOLDHIGHLIGHT > td {
        border-bottom: 1px solid #d2ab77;
        border-top: 1px solid #d2ab77;
    }

    .BOLDTOTAL > .labelField {
        color: #d2ab77;
        //background-color: #ff9614;
    }

    .BOLDTOTAL > td {
        border-bottom: 1px solid #d2ab77;
        border-top: 1px solid #d2ab77;
    }

    .selectSCN {
        background-color: white;
        border-color: #b98d27;
        //font-weight: 600;
        color: #b98d27;
    }

    th .selectSCN {
        background-color: #efefef;
        width: 110px;
    }

    .datatable-expand-button {
        height: 20px;
        line-height: 2px;
        color: inherit;
    }

    .mat-mdc-header-cell:not(.mat-column-LABEL):not(.mat-column-CLMNPAD):not(
            .mat-column-COMMENTID
        ) {
        white-space: pre-line !important;
    }

    th.data {
        color: #b98d27;
        font-weight: 600;
        /* background-color: #efefef;
            border-left: 5px solid #fff;
            min-width: 90px;
            max-width: 90px; */
    }

    .mat-mdc-table-sticky.mat-column-FYR_SCA:before {
        box-shadow: -15px 0 15px -15px inset #ccc;
        content: ' ';
        height: 100%;
        top: 0;
        left: -15px;
        position: absolute;
        width: 15px;
    }

    .mat-mdc-table-sticky.mat-column-LABEL:after,
    .mat-table-sticky.mat-column-wksnp:after,
    .mat-table-sticky.mat-column-ACCOUNT:after {
        box-shadow: 15px 0 15px -15px inset #ccc;
        content: ' ';
        height: 100%;
        position: absolute;
        top: 0;
        right: -15px;
        width: 15px;
    }

    .pms-weekly-var {
        min-width: 50%;
        text-align: center;
        font-size: 11px;
        //line-height: 24px;
        padding: 5px 8px;
    }

    td:not(.mat-column-DTYPE) .pms-weekly-data {
        width: 100%;
        padding: 2px 8px;
        border-left: 1px dashed #efefef;
        text-align: center;
        //max-width: 70px;
        min-width: 55px;
    }

    td.mat-column-DTYPE .pms-weekly-data {
        width: 100%;
        padding: 2px 8px;
        text-align: center;
        max-width: 150px;
        min-width: 150px;
    }

    th.mat-column-DTYPE:after,
    td.mat-column-DTYPE:after {
        box-shadow: 15px 0 15px -15px inset rgba(#7e93ac, 0.2);
        content: ' ';
        height: 100%;
        position: absolute;
        top: 0;
        right: -15px;
        width: 15px;
    }

    th.mat-column-DAY_100::before,
    td.mat-column-DAY_100::before {
        box-shadow: -15px 0 15px -15px inset rgba(#7e93ac, 0.2);
        content: ' ';
        height: 100%;
        top: 0;
        left: -15px;
        position: absolute;
        width: 15px;
    }

    td.pms-day {
        background-color: #b98d27 !important;
        //border-left: 1px dashed #b98d27;
        //border-right: 1px dashed #b98d27;
    }

    th.pms-day {
        background-color: #b98d27 !important;
        //border-left: 1px solid #b98d27!important;
        //border-right: 1px solid #b98d27!important;
        color: #fff;
    }

    tr.pms-weekly-row {
        background-color: #b98d27 !important;
    }

    tr.seg-group {
        border-bottom: 1px solid #7e93ac;
        border-top: 1px solid #7e93ac;
        font-weight: 600;
        background-color: #f8f8f8;

        td {
            color: #7e93ac;
        }
    }

    .var.text-green::before {
        content: '+';
    }

    th.mat-column-DAY_100:not(.pms-day),
    th.mat-column-DAY_101:not(.pms-day),
    th.mat-column-DAY_102:not(.pms-day),
    th.mat-column-DAY_103:not(.pms-day) {
        background-color: #7e93ac !important;
        color: #fff;
        border-left: 1px solid #7e93ac !important;
    }

    th.mat-column-DAY_100.pms-day,
    th.mat-column-DAY_101.pms-day,
    th.mat-column-DAY_102.pms-day,
    th.mat-column-DAY_103.pms-day {
        background-color: #b98d27 !important;
        color: #fff;
        border-left: 1px solid #b98d27 !important;
    }

    tr:not(.pms-weekly-row) td.mat-column-DAY_100:not(.pms-day),
    tr:not(.pms-weekly-row) td.mat-column-DAY_101:not(.pms-day),
    tr:not(.pms-weekly-row) td.mat-column-DAY_102:not(.pms-day),
    tr:not(.pms-weekly-row) td.mat-column-DAY_103:not(.pms-day) {
        background-color: #f8f8f8 !important;
        border-left: 1px solid #efefef !important;
    }

    td.mat-column-DAY_100.pms-day,
    td.mat-column-DAY_101.pms-day,
    td.mat-column-DAY_102.pms-day,
    td.mat-column-DAY_103.pms-day {
        background-color: rgba(#b98d27, 0.2) !important;
    }

    #weeklytable th:not(.mat-column-DTYPE):hover,
    #segTable th:not(.mat-column-DTYPE):hover {
        cursor: pointer;
        background-color: #b98d27 !important;
        color: #fff;
    }

    .date-picker {
        height: 20px;
        top: -5px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .date-picker .mat-form-field-underline {
        display: none;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .date-picker .mat-form-field-flex {
        border-radius: 4px !important;
        background-color: rgba(#b98d27, 0.2) !important;
    }

    .topline-widget {
        .outlets_header {
            font-weight: 600;
            font-size: 16px;
            padding: 20px 5px 5px 5px;
            border-bottom: 1px solid #efefef;
        }

        .levelContainer {
            padding-bottom: 20px;
        }

        td.labelField {
            text-align: left !important;
            min-width: 150px;
        }

        td.data {
            min-width: 70px;
        }

        th.data {
            text-align: center;
        }

        .total-box {
            width: 100%;
            border-radius: 5px;
            height: 200px;
            background-color: #efefef;
        }
    }

    .topline-breakdown-widget {
        .outlets_header {
            font-weight: 600;
            font-size: 16px;
            padding: 20px 5px 5px 5px;
            border-bottom: 1px solid #efefef;
        }

        td.labelField {
            text-align: left !important;
            min-width: 250px;
        }

        td.mat-column-name {
            width: auto;
        }
    }

    .mat-calendar-body-cell-content:hover {
        background-color: rgba(#7e93ac, 0.2) !important;
    }

    .mat-mdc-icon-button {
        color: #b98d27;
    }

    .mat-column-COMMENTID {
        padding-left: 10px !important;
        min-width: 400px;
        max-width: 400px;
        vertical-align: top;
        border-bottom: 0px !important;
    }

    th.mat-column-COMMENTID {
        vertical-align: middle;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 10px !important;
    }

    .mat-mdc-cell.mat-column-name {
        width: 100%;
    }

    .mat-mdc-cell:not(.mat-column-name):not(.mat-column-LABEL):not(
            .mat-column-COMMENTID
        ):not(.mat-column-NAME),
    .mat-mdc-header-cell:not(.mat-column-LABEL):not(.mat-column-COMMENTID):not(
            .mat-column-NAME
        ) {
        text-align: center !important;
        /*  > div{
            text-align: center !important;
        } */
    }

    .expanded_tr {
        vertical-align: top;
    }

    .expanded_tr td {
        padding-top: 5px !important;
    }

    .entity_selector {
        max-height: 100%;
        overflow: auto;
    }

    .selectors_container {
        border: 1px solid #efefef;
        border-radius: 5px;
        margin-top: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    tr.selection-hover:hover {
        background-color: #f8f8f8;
    }

    .progressSeg {
        background-color: #b98d27;
        height: 5px;
        margin-top: 10px;
    }

    .progress_container span {
        color: #b98d27;
        margin-top: 2px;
        padding-left: 5px;
    }

    .seg_label {
        margin-top: auto;
        margin-bottom: auto;
        width: 100%;
        text-align: left;
    }

    // RADISSON ADDITION
    .col-highlight {
        @include color-themes.themed('dx-pivot-table') {
            background-color: color-themes.get-theme-color('table-row-highlight');
        }
    }

    .col-border-right {
        border-right: 1px solid var(--border-color) !important;
    }

    .col-border-right-ap {
        border-right: 3px solid var(--border-color) !important;
    }

    .col-border-right-grey {
        border-right: 1px solid var(--border-color) !important;
    }

    .col-border-right-yellow {
        border-right: 3px solid #b18605 !important;
    }

    .col-border-left {
        border-left: 1px solid var(--border-color) !important;
    }

    .col-border-left-ap {
        border-left: 3px solid var(--border-color) !important;
    }

    .mat-mdc-table-sticky.col-border-right:after {
        box-shadow: 15px 0 15px -15px inset var(--table-shadow-color);
        content: ' ';
        height: 100%;
        position: absolute;
        top: 0;
        right: -15px;
        width: 15px;
    }

    .w-100-p {
        width: 100% !important;
    }

    .pd-lr-5 {
        padding: 0px 5px 0px 5px;
    }

    .mglr-5 {
        margin: 0px 5px 0px 5px;
    }

    .min-w-100 {
        min-width: 100px !important;
    }

    .w-100 {
        width: 100px !important;
    }

    .min-w-150 {
        min-width: 150px !important;
    }

    .min-w-140 {
        min-width: 140px !important;
    }

    .min-w-130 {
        min-width: 130px !important;
    }

    .min-w-120 {
        min-width: 120px !important;
    }

    .min-w-80 {
        min-width: 80 !important;
    }

    .min-w-60 {
        min-width: 60px !important;
    }

    .min-w-50 {
        min-width: 50px !important;
    }

    .max-w-100 {
        max-width: 100px !important;
        min-width: 90px !important;
    }

    .max-w-150 {
        max-width: 150px !important;
        min-width: 150px !important;
        width: 150px;
    }

    .max-w-60 {
        max-width: 60px;
    }

    .max-w-70 {
        max-width: 70px;
        width: 70px;
    }

    .max-w-80 {
        max-width: 80px;
        width: 80px;
    }

    .max-w-100 {
        max-width: 100px;
        width: 100px;
    }

    .max-w-110 {
        max-width: 110px;
        width: 110px;
    }

    .max-w-120 {
        max-width: 120px !important;
        width: 120px;
    }

    .max-w-180 {
        max-width: 180px;
        width: 180px;
    }

    .fixed-w-130 {
        width: 130px !important;
        max-width: 130px !important;
    }

    .alt-group2-row {
        .mat-mdc-row:nth-child(6n + 4),
        .mat-row:nth-child(6n + 5),
        .mat-row:nth-child(6n + 6) {
            @include color-themes.themed('dx-pivot-table') {
                background-color: color-themes.get-theme-color(
                    'table-row-highlight'
                ); //rgba(126, 147, 172, 0.2);
            }
        }
    }

    .alt-group4-row {
        .mat-mdc-row:nth-child(8n + 5),
        .mat-row:nth-child(8n + 6),
        .mat-row:nth-child(8n + 7),
        .mat-row:nth-child(8n + 8) {
            //background-color: #f7f2e7; //rgba(126, 147, 172, 0.2);
            @include color-themes.themed('dx-pivot-table') {
                background-color: color-themes.get-theme-color(
                    'table-row-highlight'
                ); //rgba(126, 147, 172, 0.2);
            }
        }
    }

    .mat-mdc-cell {
        @include color-themes.themed('table') {
            color: color-themes.get-theme-color('cell-color');
        }
    }

    .mat-mdc-table.table-hover {
        tbody {
            tr:hover {
                .mat-mdc-row {
                    @include color-themes.themed('dx-pivot-table') {
                        background-color: color-themes.get-theme-color(
                            'table-row-highlight'
                        ); //rgba(126, 147, 172, 0.2);
                    }
                }
            }
        }
    }

    .dropDownLabel {
        position: absolute;
        padding: 0 5px;
        top: -4px;
        left: 10px;
        font-weight: 400;
        border-radius: 3px;
        font-size: 11px;
        z-index: 1;
        color: #7e93ac;
        line-height: 5px;

        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter');
        }
    }

    .dropdown-toggle {
        color: #7e93ac;
        border-color: #7e93ac;

        @include color-themes.themed('button') {
            background-color: color-themes.get-theme-color('bg-color');
        }
    }

    .dropdown-inline {
        display: inline-block;
        margin: 0px 5px;
    }

    .btn-success {
        border: 1px solid #b38605;
        height: 31.25px;
        color: #b38605;
        font-weight: 600;
        line-height: 26px;
        // background-color: #ffffff;
    }

    .btn-success.dropdown-toggle {
        color: #fff;
        background-color: #7e93ac;
        border-color: #7e93ac;
    }

    .btn-150 {
        width: 150px;
    }

    .mat-mdc-row.row-58 {
        height: 58px !important;

        td:not(.mat-column-icon) {
            font-size: 24px !important;

            .var {
                font-size: 14px !important;
                line-height: 20px;
            }
        }
    }

    td.mat-column-icon {
        color: rgb(191, 144, 0);
    }

    table.mat-mdc-table {
        border-radius: 5px;
    }

    .tr-total:last-child {
        @include color-themes.themed('dx-pivot-table') {
            background-color: color-themes.get-theme-color(
                'table-row-highlight'
            ); //rgba(126, 147, 172, 0.2);
        }

        border-top: 1px solid #7e93ac;
        border-bottom: 1px solid #7e93ac;
        font-weight: 800;

        td {
            color: #7e93ac;
        }
    }

    .tr-wk-end {
        //background-color: #f7f2e7;
        /* border-bottom: 1px solid #b38802;
        border-top: 1px solid #b38802; */
        @include color-themes.themed('dx-pivot-table') {
            background-color: color-themes.get-theme-color(
                'table-row-highlight'
            ); //rgba(126, 147, 172, 0.2);
        }
    }

    .highlight-midweek {
        //background-color: #f7f2e7;
        @include color-themes.themed('dx-pivot-table') {
            background-color: color-themes.get-theme-color(
                'table-row-highlight'
            ); //rgba(126, 147, 172, 0.2);
        }
    }

    /* .mat-table-sticky{
        background-color: none;
    } */

    .tr-no-border-last-child:last-child {
        border-bottom: 3px solid #fff;
    }

    .first-header-row th,
    .second-header-row th {
        //background-color: white;
        border-bottom-color: #7e93ac !important;
        border-bottom-width: 1px !important;
        color: #7e93ac;
    }

    .mat-mdc-header-row.first-header-row:not(.h56),
    .mat-header-row.second-header-row:not(.h56) {
        max-height: 28px !important;
        min-height: 28px !important;
        height: 28px !important;

        th {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('th-group-bg') !important;
                border-bottom-color: variables.$app-primary !important;
            }

            border-bottom-width: 1px !important;
        }
    }

    .mat-mdc-header-row.first-header-row.h56,
    .mat-header-row.second-header-row.h56 {
        max-height: 56px !important;
        min-height: 56px !important;
        height: 56px !important;

        th {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('th-group-bg') !important;
                border-bottom-color: variables.$app-primary !important;
            }

            border-bottom-width: 1px !important;
        }
    }

    .hg-right-b:not(:last-child) {
        border-right: 1px solid #7e93ac;
    }

    .hg-right-b-ap:not(:last-child):not(:last-child) {
        border-right: 3px solid #7e93ac;
    }

    .hg-sub {
        font-size: 10px;
        font-weight: 0 !important;
        color: #b38605 !important;
    }

    .b-grey:not(:last-child) {
        border-right-color: #ccc !important;
    }

    .mat-mdc-table-hover .mat-mdc-row:hover {
        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('tr-hover-bg') !important;
        }
    }

    .d-flex {
        display: inline-flex;
    }

    @media (min-width: 1025px) {
        .row.row-col-separator-lg > div {
            border-bottom: 0;
            border-right: 1px solid #ebedf2;
        }
    }

    @media (min-width: 1400px) {
        .row.row-col-separator-lg > div {
            border-bottom: 0;
            border-right: 1px solid #ebedf2;
        }
    }

    .row.row-col-separator-lg > div:last-child {
        border-right: 0;
    }

    .kpi-success {
        font-size: 36px !important;
        color: #bf9001;
        letter-spacing: -2px;
        line-height: 35px;
    }

    .kpi-success-var {
        font-size: 14px !important;
        line-height: 20px;
    }

    .fs-18 {
        font-size: 18px;
    }

    .h-58 {
        height: 58px;
    }

    .h-34 {
        height: 34px !important;
    }

    .text-success {
        color: #b58802 !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-checked {
        color: white !important;

        @include color-themes.themed('button') {
            background-color: color-themes.get-theme-color(
                'toggle-btn-checked'
            ) !important;
        }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-appearance-standard {
        color: #7e94ac;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: 37px;
        font-size: 13px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-group.h-32 {
        height: 37px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
    .mat-button-toggle-group.mr5 {
        margin-right: 5px;
    }

    .rb tr.minor-group2-row {
        background-color: rgba(126, 147, 172, 0.2);
    }

    .growth-after:after {
        content: '%';
        margin: -2px;
    }

    .mtable-container {
        overflow: auto;
    }

    .mat-sort-header-container {
        margin: auto;
        align-items: center;
        width: fit-content;
    }

    .mat-sort-header-arrow {
        color: #bb8c03;
    }

    .btn-expand-all {
        height: 28px !important;
        width: 28px !important;
        line-height: 30px;

        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter');
        }
    }

    .table-y-overlay {
        overflow-y: hidden;
    }

    .header-group-title th h3 {
        font-weight: 800 !important;
    }

    td.mat-column-stslabel {
        transform: rotate(-90deg);
        max-width: 50px;
        font-weight: 600 !important;
        color: #b98d27 !important;
        font-size: 16px !important;
    }

    tbody tr:not(:last-child) td.rotate-td {
        transform: rotate(-90deg);
        max-width: 50px;
        font-weight: 600 !important;
        color: #b98d27 !important;
        font-size: 14px !important;
        white-space: nowrap;
        top: 25%;
        position: relative;
    }

    tbody tr:not(:last-child) td.rotate-td-w-wrap {
        transform: rotate(-90deg);
        max-width: 50px;
        font-weight: 600 !important;
        color: #b98d27 !important;
        font-size: 14px !important;
        position: relative;
    }

    .cost-driver-option {
        width: 180px;

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-underline {
            display: none;
        }
    }

    .mat-column-revpolu_bud,
    .mat-column-revpolu_ly,
    .mat-column-revpolu_off,
    .mat-column-revpolu_roll,
    .mat-column-revpolu_act,
    .mat-column-revpolu_fcst,
    .mat-column-revpolu_fcst_stat,
    .mat-column-revpolu_ly_stat,
    .mat-column-revpolu_bud_stat,
    .mat-column-tot_hrs,
    .mat-column-com_hrs,
    .mat-column-bud_hrs,
    .mat-column-stat_bud_hrs,
    .mat-column-fcst_hrs,
    .mat-column-stat_fcst_hrs,
    .mat-column-ly_hrs,
    .mat-column-stat_ly_hrs,
    .mat-column-revpolu_pulse {
        background: rgb(162 181 215 / 22%);
        font-weight: 600;
        color: #0e778b !important;
    }

    .mat-column-revpolu_gm {
        padding-right: 0px !important;
        background-color: #a4cb1773;
        font-weight: 600;
        color: #108d4d !important;
    }

    .mat-column-gm_hrs,
    .mat-column-fte_gm,
    .mat-column-cost_gm,
    .mat-column-labor_cost_gm,
    .mat-column-non_prod_percent,
    .mat-column-non_prod_percent_mte,
    .mat-column-non_prod_percent_mtd,
    .mat-column-prod_hrs_con_percent_mte,
    .mat-column-prod_hrs_con_percent_mtd,
    .mat-column-prod_index_gm,
    .mat-column-revpolu_gm {
        color: #b48703 !important;
        font-weight: 600;
        background-color: #b4880214 !important;
    }

    .prod-table tr td:nth-child(2n + 3) {
        color: #b48703;
        background-color: #b4880214 !important;
    }

    .benchTableWrapper table tr td:nth-child(5),
    .benchTableWrapper table tr td:nth-child(8),
    .benchTableWrapper table tr td:nth-child(11),
    .benchTableWrapper table tr td:nth-child(14),
    .benchTableWrapper table tr td:nth-child(17),
    .benchTableWrapper table tr td:nth-child(20),
    .benchTableWrapper table tr td:nth-child(23),
    .benchTableWrapper table tr td:nth-child(26),
    .benchTableWrapper table tr td:nth-child(29) {
        background-color: #b4880214 !important;
    }

    .budgetTableWrapper table tr td:nth-child(5),
    .budgetTableWrapper table tr td:nth-child(8),
    .budgetTableWrapper table tr td:nth-child(11),
    .budgetTableWrapper table tr td:nth-child(14),
    .budgetTableWrapper table tr td:nth-child(17),
    .budgetTableWrapper table tr td:nth-child(20),
    .budgetTableWrapper table tr td:nth-child(23),
    .budgetTableWrapper table tr td:nth-child(26),
    .budgetTableWrapper table tr td:nth-child(29) {
        background-color: #b4880214 !important;
    }

    .connectivityChannelsTableWrapper table tr td:nth-child(5),
    .connectivityChannelsTableWrapper table tr td:nth-child(8),
    .connectivityChannelsTableWrapper table tr td:nth-child(11),
    .connectivityChannelsTableWrapper table tr td:nth-child(14),
    .connectivityChannelsTableWrapper table tr td:nth-child(17),
    .connectivityChannelsTableWrapper table tr td:nth-child(20),
    .connectivityChannelsTableWrapper table tr td:nth-child(23),
    .connectivityChannelsTableWrapper table tr td:nth-child(26),
    .connectivityChannelsTableWrapper table tr td:nth-child(29),
    .connectivityChannelsTableWrapper table tr td:nth-child(32),
    .connectivityChannelsTableWrapper table tr td:nth-child(35),
    .connectivityChannelsTableWrapper table tr td:nth-child(38),
    .connectivityChannelsTableWrapper table tr td:nth-child(41) {
        background-color: #b4880214 !important;
    }

    .budgetMonthTableWrapper table tr td:nth-child(5),
    .budgetMonthTableWrapper table tr td:nth-child(8),
    .budgetMonthTableWrapper table tr td:nth-child(11),
    .budgetMonthTableWrapper table tr td:nth-child(14),
    .budgetMonthTableWrapper table tr td:nth-child(17),
    .budgetMonthTableWrapper table tr td:nth-child(20),
    .budgetMonthTableWrapper table tr td:nth-child(23),
    .budgetMonthTableWrapper table tr td:nth-child(26),
    .budgetMonthTableWrapper table tr td:nth-child(29),
    .budgetMonthTableWrapper table tr td:nth-child(32),
    .budgetMonthTableWrapper table tr td:nth-child(35),
    .budgetMonthTableWrapper table tr td:nth-child(38) {
        background-color: #b4880214 !important;
    }

    .dataTableWrapper table tr td:nth-child(5),
    .dataTableWrapper table tr td:nth-child(8),
    .dataTableWrapper table tr td:nth-child(11),
    .dataTableWrapper table tr td:nth-child(14),
    .dataTableWrapper table tr td:nth-child(17),
    .dataTableWrapper table tr td:nth-child(20),
    .dataTableWrapper table tr td:nth-child(23),
    .dataTableWrapper table tr td:nth-child(26),
    .dataTableWrapper table tr td:nth-child(29) {
        background-color: #b4880214 !important;
    }

    .mat-column-costdriver {
        max-width: 200px;
        width: 200px;
    }

    .col-hidden {
        display: none;
    }

    button.btn-search {
        display: none;
    }

    .mat-mdc-row:hover td.mat-column-label button.btn-search {
        display: block;
    }

    .mat-mdc-table-pnl {
        .mat-column-label.labelField {
            min-width: 300px;
        }

        tr.treeLevel0 {
            display: none;
        }

        tr.treeLevel2 {
            background-color: #fff;
        }

        td.mat-mdc-cell.mat-column-CLMNPAD {
            border: none !important;
        }

        th.mat-column-CLMNPAD {
            background-color: #ffffff !important;
            flex-direction: row;
            //display: flex;
            height: 40px; //100%;
            width: 10px;
            max-width: 10px;
            padding: 0 !important;
            position: relative;
            // top: -5px;
            border: none !important;
        }

        td.mat-column-CLMNPAD {
            background-color: #fff !important;
            display: flex;
            height: 28px;
            width: 5px;
            max-width: 5px;
            padding: 0 !important;
            margin-top: -1px;
            border: none !important;
        }

        .BOLDHIGHLIGHT,
        .BOLDTOTAL,
        .BOLDHIGHLIGHT > td > div,
        .BOLDTOTAL > td > div {
            font-weight: 600;
            font-style: normal;
            font-size: 13px;
            //background-color: #f8f8f8;
        }

        .BOLDHIGHLIGHT > .labelField {
            color: #b98d27;
            //background-color: $success;
        }

        .BOLDHIGHLIGHT > td {
            border-bottom: 1px solid #b98d27;
            border-top: 1px solid #b98d27;
        }

        .BOLDTOTAL > .labelField {
            color: #b98d27;
            //background-color: #ff9614;
        }

        .BOLDTOTAL > td {
            border-bottom: 1px solid #b98d27;
            border-top: 1px solid #b98d27;
        }
    }

    tr.treeLevel1 > td.labelField.MY > div,
    tr.treeLevel2 > td.labelField.MY > div,
    tr.treeLevel3 > td.labelField.MY > div,
    tr.treeLevel4 > td.labelField.MY > div,
    tr.treeLevel1 > td.labelField.YG > div,
    tr.treeLevel2 > td.labelField.YG > div,
    tr.treeLevel3 > td.labelField.YG > div,
    tr.treeLevel4 > td.labelField.YG > div,
    tr.treeLevel1 > td.labelField.YF > div,
    tr.treeLevel2 > td.labelField.YF > div,
    tr.treeLevel3 > td.labelField.YF > div,
    tr.treeLevel4 > td.labelField.YF > div {
        text-align: center !important;
    }

    tr.treeLevel1 > td.labelField.MY > div,
    tr.treeLevel1 > td.labelField.YG > div,
    tr.treeLevel1 > td.labelField.YF > div {
        padding-right: 24px;
    }

    tr.treeLevel2 > td.labelField.MY > div,
    tr.treeLevel2 > td.labelField.YG > div,
    tr.treeLevel2 > td.labelField.YF > div {
        padding-right: 44px;
    }

    tr.treeLevel3 > td.labelField.MY > div,
    tr.treeLevel3 > td.labelField.YG > div,
    tr.treeLevel3 > td.labelField.YF > div {
        padding-right: 54px;
    }

    tr.treeLevel4 > td.labelField.MY > div,
    tr.treeLevel4 > td.labelField.YG > div,
    tr.treeLevel4 > td.labelField.YF > div {
        padding-right: 64px;
    }

    tr:not(.TITLE) {
        td.labelField.MY {
            border-left: 2px solid #eee;
            border-right: 2px solid #eee;
        }

        td.labelField.YG {
            border-left: 2px solid #eee;
            border-right: 2px solid #eee;
        }

        td.labelField.YF {
            border-left: 2px solid #eee;
            border-right: 2px solid #eee;
        }
    }

    tr:not(.TITLE) {
        .mat-column-MTD_SCB,
        .mat-column-MTD_SCC,
        .mat-column-MTD_SCD,
        .mat-column-YTD_SCB,
        .mat-column-YTD_SCC,
        .mat-column-YTD_SCD {
            border-left: 2px solid #e7e7e7;
        }
    }

    tr:not(.TITLE) {
        .mat-column-MTDREVPERCENT_SCA,
        .mat-column-MTDREVPERCENT_SCB,
        .mat-column-MTDREVPERCENT_SCC,
        .mat-column-MTDREVPERCENT_SCD,
        .mat-column-MTDRATIO_SCA,
        .mat-column-MTDRATIO_SCB,
        .mat-column-MTDRATIO_SCC,
        .mat-column-MTDRATIO_SCD {
            background: #f7f7f780;
        }
    }

    .kpi-border-right {
        border-right: 1px solid lightgray;
    }

    mat-datepicker-content {
        .mat-calendar-table-header th {
            @include color-themes.themed() {
                background-color: color-themes.get-theme-color(
                    'bg-color-lighter'
                ) !important;
            }
        }
    }

    .cockpit td.mat-column-label:hover {
        font-weight: 600;
        color: #bf9000;
        cursor: pointer;
    }

    .hrs_input {
        width: calc(100% - 6px);
        border-color: transparent;
        border: 1px solid #ccc;
        border-radius: 3px;
        text-align: center;
        line-height: 14px;
        margin: 3px 3px 3px 3px;
        background-color: #b2860540;
    }

    .mat-column-DAY100 {
        font-weight: 700;
    }

    /* input[type="number" i] {
        padding: 1px 2px;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    } */

    .room-col {
        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('room-col-bg');
        }
    }

    .blank-col {
        display: none;
    }

    .sep {
        // need color-themes.themed???
        border-top: 1px solid #677d8c;
    }

    .grand-total {
        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('grand-total-bg');
        }
    }
    // end of material-table.scss
}

.menu__item-here-color {
    @include color-themes.themed('navigation') {
        color: color-themes.get-theme-color('link-item-here') !important;
    }
}

.dropdown-item-here {
    @include color-themes.themed('navigation') {
        color: color-themes.get-theme-color('dropdown-item-here-color') !important;
    }
}

.m-quick-sidebar {
    @include color-themes.themed('quick-sidenav') {
        background-color: color-themes.get-theme-color('background');
        box-shadow: 0px 0px 15px 1px color-themes.get-theme-color('box-shadow') !important;
        -webkit-box-shadow: 0px 0px 15px 1px color-themes.get-theme-color('box-shadow') !important;
        -moz-box-shadow: 0px 0px 15px 1px color-themes.get-theme-color('box-shadow') !important;
    }
}

.not-finished {
    color: red;
    font-size: 40px;
    margin-top: 20px !important;
}

$primary-palette: mat.m2-define-palette(mat.$m2-indigo-palette);
$accent-palette: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
// The warn palette is optional (defaults to red).
$warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
/*
$default-theme: mat.define-light-theme((color: (primary: $primary-palette,
                accent: $accent-palette,
                warn: $warn-palette,
            ),
        ));

$dark-theme: mat.define-dark-theme((color: (primary: $primary-palette,
                accent: $accent-palette,
                warn: $warn-palette,
                is-dark: true,
                foreground: $mat-dark-theme-foreground,
                background: $mat-dark-theme-background
            ),
        ));
*/

.dataTableWrapper {
    table {
        .first-row {
            th {
                border-bottom: 1px dashed #7e94ac !important;
                padding: 5px 8px !important;
                height: 40px;
                line-height: 40px;

                @include color-themes.themed('table') {
                    background-color: color-themes.get-theme-color(
                        'tr-cell-bg'
                    ) !important;
                }
            }
        }

        .second-row {
            th {
                @include color-themes.themed('table') {
                    background-color: color-themes.get-theme-color(
                        'table-row-border'
                    ) !important;
                }

                padding: 5px 8px !important;
                height: 40px;
                line-height: 40px;
            }
        }

        tr {
            td {
                padding: 6px;
            }

            td.e-c-v2,
            th.e-c-v2 {
                // background-color: #7e94ac !important;
                @include color-themes.themed('table') {
                    background-color: color-themes.get-theme-color('tb-e-c') !important;
                }

                width: 1px !important;
                min-width: 1px !important;
                padding: 0 !important;
            }
        }
    }

    .text-left {
        text-align: left !important;

        div {
            text-align: center !important;
        }
    }

    .minor-group-row-white {
        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('tr-cell-bg') !important;
        }

        .l-c {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('tr-cell-bg') !important;
            }
        }
    }

    .fa-chevron-down::before,
    .fa-chevron-right::before {
        color: #b98d27 !important;
    }

    .minor-group2-row {
        .fa-chevron-down,
        .fa-chevron-right {
            text-align: right !important;
        }
    }
}

.dx-list-item-before-bag.dx-list-select-checkbox-container,
.dx-list-item-before-bag.dx-list-select-radiobutton-container {
    width: 28px;
}

.dx-list-select-all:after {
    background-color: #e0e0e0;
}

.dx-checkbox-icon {
    border: 2px solid rgba(0, 0, 0, 0.34);
}

.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover > tr > td {
    background-color: #bf90000a;
    color: #000000de;
}

.dx-treelist-rowsview
    .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)
    > td,
.dx-treelist-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines) > td {
    border-top: 1px solid rgba(191, 144, 0, 0.04);
}

.dx-treelist-text-content {
    text-align: left;
}

table.dataTable > tbody > tr > th,
table.dataTable > tbody > tr > td {
    padding: 0 !important;
}

#treelist tr td {
    text-align: center !important;
    border-right: 1px solid #e0e0e0;
}

// code repeated 10 times... placed here
.dataTableWrapperStyle {
    table {
        .first-row {
            th {
                border-bottom: 1px dashed #7e94ac !important;
                padding: 5px 8px !important;
                height: 40px;
                line-height: 40px;

                @include color-themes.themed('table') {
                    background-color: color-themes.get-theme-color(
                        'tr-cell-bg'
                    ) !important;
                }
            }
        }

        .second-row {
            th {
                @include color-themes.themed('table') {
                    background-color: color-themes.get-theme-color(
                        'table-row-border'
                    ) !important;
                }

                padding: 5px 8px !important;
                height: 40px;
                line-height: 40px;
            }
        }

        tr {
            td {
                padding: 6px;
            }

            td.e-c-v2,
            th.e-c-v2 {
                // background-color: #7e94ac !important;
                @include color-themes.themed('table') {
                    background-color: color-themes.get-theme-color('tb-e-c') !important;
                }

                width: 1px !important;
                min-width: 1px !important;
                padding: 0 !important;
            }
        }
    }

    .text-left {
        text-align: left !important;

        div {
            text-align: center !important;
        }
    }

    .minor-group-row-white {
        @include color-themes.themed('table') {
            background-color: color-themes.get-theme-color('tr-cell-bg') !important;
        }

        .l-c {
            @include color-themes.themed('table') {
                background-color: color-themes.get-theme-color('tr-cell-bg') !important;
            }
        }
    }

    .fa-chevron-down::before,
    .fa-chevron-right::before {
        color: #b98d27 !important;
    }

    .minor-group2-row {
        .fa-chevron-down,
        .fa-chevron-right {
            text-align: right !important;
        }
    }

    .btn-full-width {
        width: 100%;
    }
    .btn-warn {
        background-color: tomato;
    }
}
.modal-component{
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    margin: auto;
  
  }

  .warning-box {
    display: flex;
    background: #fef3f2;
    border: 1px solid #f7c6c5;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    align-items: center;

    .warning-icon {
      font-size: 24px;
      margin-right: 10px;
      color: #d9534f; // Red color for the icon
    }

    .warning-content {
      text-align: left;

      strong {
        display: block;
        color: #b33a3a; // Dark red color for the title
        margin-bottom: 4px;
      }

      p {
        font-size: 14px;
        color: #555;
      }
    }
  }


  .info-box {
    display: flex;
    background: #f2f4f2;
    border: 1px solid #71c283;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    align-items: center;

    .info-icon {
      font-size: 24px;
      margin-right: 10px;
      color: #71c283; // Red color for the icon
    }

    .info-content {
      text-align: left;

      strong {
        display: block;
        color: #71c283; // Dark red color for the title
        margin-bottom: 4px;
      }

      p {
        font-size: 14px;
        color: #555;
      }
    }
  }