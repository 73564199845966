@use 'swiper/scss';
@use 'sass:color';
@use 'variables';
@use 'color-themes';
/* You can add global styles to this file, and also import other style files */

/* Some class name abrevaitions used ::after

t-c : was text-center
e-c : was empty-cell
n-c : was numeric cell
l-c : was label cell

*/

:root {
    --color-black: #000;
    --icon-sun: #bf9000;
    --icon-moon: #b1b0b5;
    --switch-bg: #7e94ac;
}

:root[theme-data='light'] {
    --bg-color-bottom-header: #7e94ac;
    --text-color: #575962;
    --bg-mat-header-cell: #efefef;
    --bg-tree-level-2: #e6ebef;
    --bg-anchor-menu: #7e94ac;
    --bg-options-filter: rgba(255, 255, 255, 1);
    --bg-anchor-hover: #eee;
    --color-anchor-hover: #bf9000;
    --color-anchor: #d2d9de;
    --border-color-anchor: #ffffff;
    --table-hover-color: #efefef;
    --text-green: #009900;
    --text-red: #ff0000;
    --border-color: #7e93ac;
    --table-shadow-color: #ccc;
    --widget-title-color: #565962;
    --row-highlight-color: #7e93ac;
    --grand-total-bg: #{variables.$white-smoke};
    --light-blue: #d2dee6;
    --gray-cell: lightgray;
    --ota-trend-row-highlight: #f9fafc;
    --ota-trend-row-highlight-hover: rgba(0, 0, 0, 0.075);
    --ota-trend-row-highlight-border: #efefef;
    --ota-trend-row-highlight-today: lightgray;
    --banded-cell: lightgray;
    --table-row-border: #efefef;
    --table-row-dark: #ffffff;
}

:root[theme-data='dark'] {
    --bg-color-bottom-header: #{variables.$dark-lighter};
    --text-color: #fff;
    --bg-mat-header-cell: #151521;
    --bg-tree-level-2: hsl(240, 22%, 28%);
    --bg-anchor-menu: #1e1e2d;
    --bg-options-filter: #151521;
    --bg-anchor-hover: #1b1b29;
    --color-anchor-hover: #009ef7;
    --color-anchor: #92929f;
    --border-color-anchor: #92929f;
    --table-hover-color: hsl(240, 20%, 20%);
    --text-green: #50cd89;
    --text-red: #f1416c;
    --border-color: #000;
    --table-shadow-color: #000;
    --widget-title-color: #d9dadf;
    --row-highlight-color: #bf9000;
    --grand-total-bg: #2c2235;
    --light-blue: rgba(59, 132, 211, 0.5);
    --gray-cell: rgba(108, 108, 120, 0.5);
    --ota-trend-row-highlight: #{color.adjust(variables.$dark-lighter, $lightness: -2.5%)};
    --ota-trend-row-highlight-hover: #364266;
    --ota-trend-row-highlight-border: #151521;
    --ota-trend-row-highlight-today: #{color.adjust(
            variables.$dark-lighter,
            $lightness: -6%
        )};
    --banded-cell: #{color.adjust(#3d3d5f, $lightness: 5%)};
    --table-row-border: #1e1e2d;
    --table-row-dark: #1e1e2d;
}

.bg-lighter {
    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('bg-lighter');
    }
}

.row-v-center {
    display: flex;
    align-items: center;
    gap: 8px;
}

.h-full {
    height: 100%;
}
.light-blue-cell {
    @include color-themes.themed('table') {
        background-color: color-themes.get-theme-color('light-blue') !important;
    }
}
.text-green {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text-green');
    }
}
.text-red {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text-red');
    }
}
.text-italic-green {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text-green');
    }
    font-style: italic;
}
.text-italic-red {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text-red');
    }
    font-style: italic;
}
.text-bold {
    font-weight: bold;
}

.m-l-20 {
    margin-left: 20px !important;
}
.perfect-scrollbar-container {
    flex: 1;
    overflow: hidden;
}
a.nav-link {
    color: rgba(0, 0, 0, 0.87) !important;
}
a.nav-link:hover {
    color: rgb(126, 148, 172) !important;
}

@media print {
    .swiper-slide {
        break-inside: avoid;
    }
    .highcharts-container {
        width: 100%;
    }
    .no-print,
    .no-print * {
        display: none !important;
    }
    .pie-chart {
        max-width: 600px;
    }
}

.app-footer {
    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('bg-color-lighter') !important;
    }
}
.app-footer-home-page {
    position: fixed;
    bottom: 30px;
    right: 0;
    left: 0;
    height: 30px;
    min-height: 30px;
    .m-container {
        padding: 0 5px !important;
    }
}
//----

.swiper-slide {
    height: auto; // Required to display the margins between slides at chrome
}
//slides CSS
.logoMaxWidth {
    max-width: 400px;
}
.slide-container {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 100%;
}
.swiper-wrapper {
    box-sizing: inherit;
    height: auto;
}
.swiper-wrapper.disabled {
    transform: translate3d(0px, 0, 0) !important;
    display: block !important;
}
.swiper-pagination {
    z-index: 1 !important;
}
.swiper-pagination-fraction {
    color: #ffffff;
    width: 50px;
    padding-top: 10px;
    padding-left: 5px;
}
.slide-footer {
    background: #7e94ac;
    height: 65px;
    padding: 10px;
    display: inline-flex;
    width: calc(100% - 60px);
    margin: 0px 30px;
    border-radius: 10px;
    @include color-themes.themed('footer') {
        background: color-themes.get-theme-color('bg-color');
    }
}
.slide-footer img {
    height: 50px;
}
.mr-auto > h3 {
    height: 100%;
    line-height: 100%;
    margin-top: 14px;
    margin-left: 30px;
}
.m-grid--root.m-page,
.h100p {
    height: 100%;
}
#slider {
    .m-page__container {
        padding-top: 30px;
        padding-bottom: 30px;
        height: 100%;
    }
    .m-grid__item {
        height: 100%;
    }
}
.slider-resize,
.slider-resize-portlet-body {
    height: 100% !important;
}
.slider-resize-portlet-body.after-head {
    height: calc(100% - 71px) !important;
}
#cover-page h1 {
    font-size: 70px;
    color: #7e94ac !important;
}
#cover-page {
    height: 100%;
    width: 100%;
    display: none;
}
#slider.animateUp {
    padding-top: 0px !important;
    height: 100% !important;
    -webkit-transition: padding-top 0.2s ease-out;
    -moz-transition: padding-top 0.2s ease-out;
    -o-transition: padding-top 0.2s ease-out;
    transition: padding-top 0.2s ease-out;
}
#slider.animateDown {
    padding-top: 95px !important;
    height: auto !important;
    -webkit-transition: padding-top 0.2s ease-out;
    -moz-transition: padding-top 0.2s ease-out;
    -o-transition: padding-top 0.2s ease-out;
    transition: padding-top 0.2s ease-out;
}
.swiper {
    z-index: 0 !important;
}
.screen-view {
    height: 400px !important;
}
.slide-view {
    height: 100% !important;
}
.swiper-button-prev,
.swiper-button-next {
    color: #d9d9d9;
    background-image: none;
}
.swiper-button-prev i,
.swiper-button-next i {
    font-size: 4em;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
    color: #d1d1d1;
}
.slideLogo {
    width: 300px;
}
.slideIntroTitle {
    letter-spacing: -2px;
    width: 100%;
    text-align: center;
    padding-top: 100px;
}
#swiper-flex-container {
    height: 100%;
    /* padding-bottom: 30px; */
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
}
.swiper {
    height: 100%;
}
/* vh implemented to avoid recalc on echarts, better solution? */
div#the-swiper-wrapper:not(.disabled) {
    .snap-active {
        .slide-container.m-portlet.slider-resize {
            box-shadow: none;
        }
        .snap-scroll {
            overflow-y: auto;
        }
    }
}
.close-tab {
    overflow: hidden;
    width: 50px;
    padding: 8px;
}
.close-tab a {
    float: right;
    display: block;
    text-decoration: none;
    /* padding-top: 20px; */
    color: #d9d9d9;
}
.close-tab a:hover {
    color: #d1d1d1;
}
.close-tab a i {
    font-size: 2em;
}
.close-tab {
    dx-button {
        border-radius: 50% !important;
    }
    dx-button .dx-button-content {
        width: auto;
        height: auto;
    }
    .dx-button .dx-icon {
        color: #7e94ac;
    }
}
.tooltip-inner {
    background-color: rgb(132, 151, 176) !important;
}
/* .tooltip.top .tooltip-arrow  {
    border-top-color: rgb(132, 151, 176) !important; 
} */
.tooltip.right .tooltip-arrow {
    border-right: 5px solid rgb(132, 151, 176) !important;
}
.tooltip.top .tooltip-arrow {
    border-top: 5px solid rgb(132, 151, 176) !important;
}
.tooltip.left .tooltip-arrow {
    border-left: 5px solid rgb(132, 151, 176) !important;
}
.tooltip.bottom .tooltip-arrow {
    border-bottom: 5px solid rgb(132, 151, 176) !important;
}
.tooltip.top .arrow::before {
    border-color: rgb(132, 151, 176) !important;
}
.tooltip.bs-tooltip-right .arrow:before {
    border-right-color: rgb(132, 151, 176) !important;
}
.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: rgb(132, 151, 176) !important;
}
.tooltip.bs-tooltip-bottom .arrow:before {
    border-bottom-color: rgb(132, 151, 176) !important;
}
.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: rgb(132, 151, 176) !important;
}
.loading-section {
    width: 100%;
    height: calc(100% - 80px);
    //margin: -10px -20px;
    float: right;
    z-index: 100;
    position: absolute;
    @include color-themes.themed('dashboard') {
        background: color-themes.get-theme-color('loading-section-bg');
    }
    div {
        margin: 10px;
    }
}
.no-data-section {
    width: 100%;
    height: calc(100% - 100px);
    margin: -10px -20px;
    float: right;
    z-index: 100;
    position: absolute;
    @include color-themes.themed('dashboard') {
        background: color-themes.get-theme-color('no-data-section');
    }
    div {
        margin: 10px;
    }
}
.no-data-section-b {
    width: calc(100% - 30px);
    height: calc(100% - 40px);
    margin: -10px -20px;
    float: right;
    z-index: 100;
    position: absolute;
    @include color-themes.themed('dashboard') {
        background: color-themes.get-theme-color('no-data-section');
    }
    div {
        margin: 10px;
    }
}
.ngx-date-filter {
    width: 180px;
    height: 43px;
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    margin-top: 1px;
    color: #7894a6;
    font-weight: 500;
    border: 1px solid;
    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('date-picker-bg');
    }
}
.daterangepickerdisabled {
    span,
    button {
        cursor: default;
        pointer-events: none;
    }
    span.m-subheader__daterange {
        background-color: rgba(248, 248, 251, 0.5);
    }
    span.m--font-brand {
        color: rgba(120, 148, 166, 0.5) !important;
    }
}
.dropdown-toggle.no-anchor::after {
    display: none;
}
.hand {
    cursor: pointer;
}
/* Tooltip */
.header-tip {
    color: #477799;
    margin-left: 20px;
    font-size: 1.3rem;
    font-weight: 500;
    font-family: Roboto;
    i {
        font-size: 2rem;
    }
}
.bs-tooltip-left,
.bs-tooltip-right {
    min-width: 200px;
}
.comment-ico-wrapper {
    margin-left: 10px;
    cursor: pointer;
    position: relative;
    i {
        text-align: center;
        font-size: 1.6rem;
    }
    i.grey {
        color: #d2d9de;
    }
    i.lightblue {
        color: #477799;
    }
    .pulse-badge {
        color: #477799;
        background: #d2d9de;
        position: absolute;
        top: -8px;
        right: -8px;
        font-size: 50% !important;
        font-weight: 500 !important;
    }
}
/* End Tooltip */
.dx-pseudo-disabled {
    .dx-master-detail-cell {
        .dx-state-disabled.dx-widget {
            opacity: 1 !important;
        }
        .dx-texteditor-container {
            pointer-events: auto !important;
        }
    }
}
/* Override bootstrap btn-primary */
.btn-primary {
    background-color: #bf9000 !important;
    border-color: #a17902 !important;
}
.btn-primary:hover {
    background-color: #cfa422 !important;
}
.btn-primary:not(:disabled):not(.disabled):active {
    background-color: #a97a00 !important;
}
.btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: #efefef !important;
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    background-color: #7e94ac !important;
    color: #fff !important;
}
.btn-outline-primary {
    color: #7e94ac !important;
    border-color: #7e94ac !important;
}
.btn-outline-primary:hover {
    background-color: #7e94ac !important;
    color: #fff !important;
}
.dropdown-item.active,
.dropdown-item:active {
    background-color: #7e94ac !important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    background-color: #7e94ac !important;
    color: #fff !important;
}
.m-btn--icon.m-btn--icon-only {
    display: inline-block;
    position: relative;
    padding: 0 !important;
    width: 33px;
    height: 33px;
}
.radio span {
    margin: 0px 10px 0px 5px;
}

.customClass .progress-bar {
    background: #7e94ac !important;
    width: 100%;
}
.customClass button.btn.btn-link {
    color: #7894a6;
    font-weight: 500;
    text-decoration: none;
    border: none;
}
/*
    Dynamic screen params for slides
    In echartSlide the number means % of max
    Map: echartSlide100
    Map Top/flop: echartSlide50
    Use echartSlideMinus__ to compensate for data tables.
*/
.h100 {
    height: 100px;
}
.h150 {
    height: 150px;
}
.h175 {
    height: 175px;
}
.h225 {
    height: 225px;
}
.h260 {
    height: 260px;
}
.h275 {
    height: 275px;
}
.h300 {
    height: 300px;
}
.h350 {
    height: 350px;
}
.h400 {
    height: 400px;
}
.h450 {
    height: 450px;
}
.h500 {
    height: 500px;
}
.h590 {
    height: 590px;
}
.h800 {
    height: 800px;
}
@media (max-height: 767.98px) {
    div#the-swiper-wrapper:not(.disabled) {
        .snap-active {
            height: 72vh;
        }
    }
    .echartSlide100 {
        height: 60vh;
    }
    .echartSlide75 {
        height: 45vh;
    }
    .echartSlide75Minus80 {
        height: calc(45vh - 80px);
    }
    .echartSlide75Minus60 {
        height: calc(45vh - 60px);
    }
    .echartSlide50 {
        height: 27vh;
    }
    .echartSlide50Minus10 {
        height: calc(27vh - 10px);
    }
    .echartSlide50Minus20 {
        height: calc(27vh - 20px);
    }
    .echartSlide50Minus40 {
        height: calc(27vh - 40px);
    }
    .echartSlide50Minus80 {
        height: calc(27vh - 80px);
    }
    .echartSlide25 {
        height: 11vh;
    }
    .echartSlide25Minus20 {
        height: calc(11vh - 20px);
    }
    .echartSlideMinus40 {
        height: calc(60vh - 40px);
    }
    .echartSlideMinus160 {
        height: calc(60vh - 160px);
    }
    .echartSlideMinus190 {
        height: calc(60vh - 190px);
    }
    .echartSlideMinus240 {
        height: calc(60vh - 240px);
    }
    .echartSlideMinus320 {
        height: calc(60vh - 320px);
    }
    .echartSlideMinus360 {
        height: calc(60vh - 360px);
    }
}
@media (min-height: 768px) and (max-height: 899.98px) {
    div#the-swiper-wrapper:not(.disabled) {
        .snap-active {
            height: 74vh;
        }
    }
    .echartSlide100 {
        height: 63vh;
    }
    .echartSlide75 {
        height: 48vh;
    }
    .echartSlide75Minus80 {
        height: calc(48vh - 80px);
    }
    .echartSlide75Minus60 {
        height: calc(48vh - 60px);
    }
    .echartSlide50 {
        height: 30vh;
    }
    .echartSlide50Minus10 {
        height: calc(30vh - 10px);
    }
    .echartSlide50Minus20 {
        height: calc(30vh - 20px);
    }
    .echartSlide50Minus40 {
        height: calc(30vh - 40px);
    }
    .echartSlide50Minus80 {
        height: calc(30vh - 80px);
    }
    .echartSlide25 {
        height: 13vh;
    }
    .echartSlide25Minus20 {
        height: calc(13vh - 20px);
    }
    .echartSlideMinus40 {
        height: calc(64vh - 40px);
    }
    .echartSlideMinus160 {
        height: calc(64vh - 160px);
    }
    .echartSlideMinus190 {
        height: calc(64vh - 190px);
    }
    .echartSlideMinus240 {
        height: calc(64vh - 240px);
    }
    .echartSlideMinus320 {
        height: calc(64vh - 320px);
    }
    .echartSlideMinus360 {
        height: calc(64vh - 360px);
    }
}
@media (min-height: 900px) and (max-height: 1079.98px) {
    div#the-swiper-wrapper:not(.disabled) {
        .snap-active {
            height: 78vh;
        }
    }
    .echartSlide100 {
        height: 67vh;
    }
    .echartSlide75 {
        height: 51vh;
    }
    .echartSlide75Minus80 {
        height: calc(51vh - 80px);
    }
    .echartSlide75Minus60 {
        height: calc(51vh - 60px);
    }
    .echartSlide50 {
        height: 31vh;
    }
    .echartSlide50Minus10 {
        height: calc(31vh - 10px);
    }
    .echartSlide50Minus20 {
        height: calc(31vh - 20px);
    }
    .echartSlide50Minus40 {
        height: calc(31vh - 40px);
    }
    .echartSlide50Minus80 {
        height: calc(31vh - 80px);
    }
    .echartSlide25 {
        height: 14vh;
    }
    .echartSlide25Minus20 {
        height: calc(14vh - 20px);
    }
    .echartSlideMinus40 {
        height: calc(67vh - 40px);
    }
    .echartSlideMinus160 {
        height: calc(67vh - 160px);
    }
    .echartSlideMinus190 {
        height: calc(67vh - 190px);
    }
    .echartSlideMinus240 {
        height: calc(67vh - 240px);
    }
    .echartSlideMinus320 {
        height: calc(67vh - 320px);
    }
    .echartSlideMinus360 {
        height: calc(67vh - 360px);
    }
}
@media (min-height: 1080px) {
    div#the-swiper-wrapper:not(.disabled) {
        .snap-active {
            height: 82vh;
        }
    }
    .echartSlide100 {
        height: 72vh;
    }
    .echartSlide75 {
        height: 54vh;
    }
    .echartSlide75Minus80 {
        height: calc(54vh - 80px);
    }
    .echartSlide75Minus60 {
        height: calc(54vh - 60px);
    }
    .echartSlide50 {
        height: 34vh;
    }
    .echartSlide50Minus10 {
        height: calc(34vh - 10px);
    }
    .echartSlide50Minus20 {
        height: calc(34vh - 20px);
    }
    .echartSlide50Minus40 {
        height: calc(34vh - 40px);
    }
    .echartSlide50Minus80 {
        height: calc(34vh - 80px);
    }
    .echartSlide25 {
        height: 15vh;
    }
    .echartSlide25Minus20 {
        height: calc(15vh - 20px);
    }
    .echartSlideMinus40 {
        height: calc(72vh - 40px);
    }
    .echartSlideMinus160 {
        height: calc(72vh - 160px);
    }
    .echartSlideMinus190 {
        height: calc(72vh - 190px);
    }
    .echartSlideMinus240 {
        height: calc(72vh - 240px);
    }
    .echartSlideMinus320 {
        height: calc(72vh - 320px);
    }
    .echartSlideMinus360 {
        height: calc(72vh - 360px);
    }
}
.dropdown-item.active,
.dropdown-item:active {
    background-color: #bf9000 !important;
}
.btn-fold {
    height: 20px;
    width: 21px;
    padding: 0;
    position: absolute;
    right: 1rem;
    background-color: white;
}
.div-fold-title {
    position: absolute;
    margin-left: 22px;
}
.fa-fold {
    // transform: rotate(-45deg);
    font-size: 16px;
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
}

// Dashboard floating filter styles
// no scroll
#absolute-report-filter-sp {
    border-radius: 15px;
    position: fixed;
    z-index: 98;
    right: calc(4% + 250px);
    top: 188px;
    top: 90px;
    padding: 9px;
    @include color-themes.themed('sticky-filter') {
        -webkit-box-shadow:
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
        -moz-box-shadow:
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
        box-shadow:
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
        background-color: color-themes.get-theme-color('bg-options-filter') !important;
    }
    /* input {
        color: white;
    } */
}

div.animateUp {
    #absolute-report-filter-sp {
        &.absolute-report-filter-sp-slide-filters-shown {
            top: 0px !important;
            padding: 5px 20px 2px 20px !important;
            right: 2%;
            left: 2%;
            box-shadow: none;
        }

        &:not(.absolute-report-filter-sp-slide-filters-shown) {
            top: 55px !important;
        }
    }
}

body.m-header--minimize-off {
    #absolute-report-filter-sp {
        -moz-transition: all 0.45s ease;
        -ms-transition: all 0.45s ease;
        -o-transition: all 0.45s ease;
        transition: all 0.45s ease;
    }
}

// half scroll
body.dashboard-filter-scroll {
    #absolute-report-filter-sp {
        top: 160px;
        padding: 9px;
        @include color-themes.themed('sticky-filter') {
            -webkit-box-shadow:
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
            -moz-box-shadow:
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
            box-shadow:
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
                -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
            background-color: color-themes.get-theme-color(
                'bg-options-filter'
            ) !important;
        }
    }
}

// full scroll
body.m-header--minimize-on {
    #absolute-report-filter-sp {
        top: 60px !important;
        -moz-transition: all 0.45s ease 0.25s;
        -ms-transition: all 0.45s ease 0.25s;
        -o-transition: all 0.45s ease 0.25s;
        transition: all 0.45s ease 0.25s;
        padding: 9px;
        @include color-themes.themed('sticky-filter') {
            background-color: color-themes.get-theme-color('bg-options-filter');
        }
    }
}

.additional-filters-shown {
    top: 90px !important;
    padding: 15px 20px 5px 20px !important;
    border-radius: 15px 15px 15px 15px !important;
    left: 4%;
    @include color-themes.themed('sticky-filter') {
        -webkit-box-shadow:
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
        -moz-box-shadow:
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
        box-shadow:
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            10px 10px 10px -10px color-themes.get-theme-color('box-shadow'),
            -10px 10px 10px -10px color-themes.get-theme-color('box-shadow');
        background-color: color-themes.get-theme-color('bg-options-filter') !important;
    }
    margin: 0;
}

.absolute-report-filter-sp-slide {
    box-shadow: none !important;
    padding: 0px !important;
    background-color: rgba(255, 255, 255, 0) !important;
}
@keyframes slide {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -120px 60px;
    }
}
// Warning message (in ops report for example)
#InfoBanner {
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

.reversed {
    display: inline-block;
    padding: 0.3em;
    padding-left: 0.3em;
    margin-left: 0.8em;
    position: relative;
    text-align: center;
    vertical-align: middle;
    line-height: 1;
    color: #fff;
    font-size: 15px;
    background-color: #ef5350;
    border-radius: 5px;
    margin-right: 12px;
}

@keyframes tilt {
    0% {
        left: 0%;
    }
    50% {
        left: 9px;
    }
    100% {
        left: 0px;
    }
}

.bgAnimation {
    background-color: #ef5350;
    background-image: linear-gradient(
            45deg,
            #e57373 25%,
            transparent 25%,
            transparent 75%,
            #e57373 75%,
            #e57373
        ),
        linear-gradient(
            -45deg,
            #e57373 25%,
            transparent 25%,
            transparent 75%,
            #e57373 75%,
            #e57373
        );
    background-size: 60px 60px;
    animation: slide 4s infinite linear;
}

.m-portlet__head-text {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text') !important;
    }
}

#loading {
    .m-blockui {
        @include color-themes.themed() {
            background-color: color-themes.get-theme-color('bg-color-lighter') !important;
        }
    }
}

.btn-container {
    @include color-themes.themed('button') {
        background: color-themes.get-theme-color('bg-color-lighter');
        color: white;
        border: 1px solid variables.$app-primary;
        height: 31px;
        border-radius: 5px;
    }
}

/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: lightgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.m-portlet__head {
    @include color-themes.themed() {
        border-bottom: 1px solid color-themes.get-theme-color('divider') !important;
    }
}

.mat-mdc-menu-content {
    .container {
        padding: 0.5rem 1rem !important;
    }
    .mat-mdc-menu-item {
        line-height: 28px;
        height: 28px;
    }
}

.m-scroll-top {
    @include color-themes.themed('button') {
        background-color: color-themes.get-theme-color('bg-color');
    }
}

.m-header {
    @include color-themes.themed('header') {
        -webkit-box-shadow: 0px 1px 15px 1px color-themes.get-theme-color('box-shadow') !important;
        -moz-box-shadow: 0px 1px 15px 1px color-themes.get-theme-color('box-shadow') !important;
        box-shadow: 0px 1px 15px 1px color-themes.get-theme-color('box-shadow') !important;
    }
}

.dx-pivotgrid-fields-container {
    @include color-themes.themed('dx-pivot-table') {
        background-color: color-themes.get-theme-color('bg-color-lighter') !important;
        color: color-themes.get-theme-color('text') !important;
        .dx-area-caption {
            color: color-themes.get-theme-color('caption') !important;
        }

        .dx-empty-message {
            color: color-themes.get-theme-color('text');
        }
    }
    .dx-pivotgrid-fields-area-head {
        background-color: variables.$white-smoke;
    }
}

.dx-grandtotal {
    @include color-themes.themed() {
        background-color: color-themes.get-theme-color('bg-color-lighter') !important;
    }
}

.dx-button {
    @include color-themes.themed('button') {
        background-color: color-themes.get-theme-color('bg-color') !important;
        color: color-themes.get-theme-color('text') !important;
    }
}

.dx-data-header {
    @include color-themes.themed('dx-pivot-table') {
        background-color: color-themes.get-theme-color('data-header-bg') !important;
    }
}

#dataGridContainer {
    table,
    tr,
    td {
        @include color-themes.themed() {
            color: color-themes.get-theme-color('text') !important;
        }
    }
}

.dx-popup-wrapper > .dx-overlay-content {
    @include color-themes.themed('dx-pivot-table') {
        background-color: color-themes.get-theme-color('pop-up-bg') !important;
        .dx-list-item {
            color: color-themes.get-theme-color('text') !important;
        }
    }
}

.dx-texteditor-input {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text') !important;
    }
}

// fa geo color-themes.themed mixin doesn't work in angular component css files
.geo-col {
    @include color-themes.themed('table') {
        background-color: color-themes.get-theme-color('grand-total-bg');
    }
    border-left: 1px solid variables.$app-primary;
    border-right: 1px solid variables.$app-primary;
    width: 80px;
    .btn {
        @extend %round-button;
        @include color-themes.themed() {
            border: 1px solid color-themes.get-theme-color('bg-color');
            background-color: color-themes.get-theme-color('bg-color-lighter');
            color: color-themes.get-theme-color('text');
        }
    }
    .btn.selected {
        background-color: variables.$app-primary;
        color: white;
    }
}

.m-widget14__title {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text') !important;
    }
}

.odd td {
    @include color-themes.themed() {
        color: color-themes.get-theme-color('text') !important;
    }
}
.dx-treelist-container {
    @include color-themes.themed('dx-pivot-table') {
        background-color: color-themes.get-theme-color('pop-up-bg') !important;
        .dx-list-item {
            color: color-themes.get-theme-color('text') !important;
        }
        .dx-treelist-text-content {
            color: color-themes.get-theme-color('text') !important;
        }
    }
}
.mat-mdc-tooltip {
    font-size: 12px !important;
}
.dx-widget {
    letter-spacing: normal;
}

.pulse-modal {
    z-index: 1050 !important; // Ensure modal is above other elements
}

.cdk-overlay-container {
    z-index: 1100 !important; // Ensure dropdown is above the modal
}

.dt-scroll-body {
    thead,
    tfoot {
        tr {
            height: 0;

            th,
            td {
                height: 0 !important;
                padding-top: 0px !important;
                padding-bottom: 0px !important;
                border-top-width: 0px !important;
                border-bottom-width: 0px !important;

                div.dt-scroll-sizing {
                    height: 0 !important;
                    overflow: hidden !important;
                }
            }
        }
    }
}

// content of _placeholders.scss
%round-button {
    position: relative;
    display: block;
    width: 43px;
    height: 43px;
    border-radius: 60px;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    margin-left: 10px;
}

%center-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
